import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useEffect } from 'react';
import {
  chainIdToNameListVar,
  clusterIdToNameListVar,
  countryIdToNameListVar,
  ownRetailChainClusterPartnersVar,
  ownRetailChainCountryPartnersVar,
  partnerIdToNameListVar,
  partnerRetailChainClusterPartnersVar,
  partnerRetailChainCountryPartnersVar,
  partnerToCompositeVar,
  semiOwnedChainClusterPartnersVar,
  semiOwnedChainCountryPartnersVar,
} from 'src/infrastructure/local_state';
import { buildLookupTables } from 'src/utils/buildLookupTables';
import { useStores } from '../store-selection/queries/useStores';

export const useInitializeLookupTables = () => {
  const [_partnerToComposite, setPartnerToComposite] = useReactiveVar(partnerToCompositeVar);
  const [_chainIdToNameList, setChainIdToNameList] = useReactiveVar(chainIdToNameListVar);
  const [_clusterIdToNameList, setClusterIdToNameList] = useReactiveVar(clusterIdToNameListVar);
  const [_partnerIdToNameList, setPartnerIdToNameList] = useReactiveVar(partnerIdToNameListVar);
  const [_countryIdToNameList, setCountryIdToNameList] = useReactiveVar(countryIdToNameListVar);
  const [_ownRetailChainCountryPartners, setOwnRetailChainCountryPartners] = useReactiveVar(
    ownRetailChainCountryPartnersVar
  );
  const [_ownRetailChainClusterPartners, setOwnRetailChainClusterPartners] = useReactiveVar(
    ownRetailChainClusterPartnersVar
  );
  const [_partnerRetailChainCountryPartners, setPartnerRetailChainCountryPartners] = useReactiveVar(
    partnerRetailChainCountryPartnersVar
  );
  const [_partnerRetailChainClusterPartners, setPartnerRetailChainClusterPartners] = useReactiveVar(
    partnerRetailChainClusterPartnersVar
  );
  const [_semiOwnedChainCountryPartners, setSemiOwnedChainCountryPartners] = useReactiveVar(
    semiOwnedChainCountryPartnersVar
  );
  const [_semiOwnedChainClusterPartners, setSemiOwnedChainClusterPartners] = useReactiveVar(
    semiOwnedChainClusterPartnersVar
  );

  const { data } = useStores();

  useEffect(() => {
    if (!data) return;

    const lookupTables = buildLookupTables(data.allStores);

    setChainIdToNameList(lookupTables.chainIds);
    setClusterIdToNameList(lookupTables.clusterIds);
    setPartnerIdToNameList(lookupTables.partnerIds);
    setCountryIdToNameList(lookupTables.countryIds);
    setOwnRetailChainCountryPartners(lookupTables.ownRetailChainCountryPartners);
    setOwnRetailChainClusterPartners(lookupTables.ownRetailChainClusterPartners);
    setPartnerRetailChainCountryPartners(lookupTables.partnerRetailChainCountryPartners);
    setPartnerRetailChainClusterPartners(lookupTables.partnerRetailChainClusterPartners);
    setSemiOwnedChainCountryPartners(lookupTables.semiOwnedChainCountryPartners);
    setSemiOwnedChainClusterPartners(lookupTables.semiOwnedChainClusterPartners);
    setPartnerToComposite(lookupTables.compositePartners);
  }, [
    data,
    setChainIdToNameList,
    setClusterIdToNameList,
    setCountryIdToNameList,
    setOwnRetailChainClusterPartners,
    setOwnRetailChainCountryPartners,
    setPartnerIdToNameList,
    setPartnerRetailChainClusterPartners,
    setPartnerRetailChainCountryPartners,
    setPartnerToComposite,
    setSemiOwnedChainClusterPartners,
    setSemiOwnedChainCountryPartners,
  ]);
};
