import { useCallback, useEffect, useState } from 'react';
import { Module } from 'src/domain';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useStyleTimelineRows } from '../table/useStyleTimelineRows';

const customMerge = (
  row: StyleTimelineOverviewRow,
  simulatedRow: StyleTimelineOverviewRow
): StyleTimelineOverviewRow => {
  return (Object.keys(row) as (keyof StyleTimelineOverviewRow)[]).reduce((acc, key) => {
    if (simulatedRow[key] !== undefined && simulatedRow[key] !== null) {
      return { ...acc, [key]: simulatedRow[key] };
    } else {
      return { ...acc, [key]: row[key] };
    }
  }, {} as StyleTimelineOverviewRow);
};

export function mergeRowsWithCache(cache: StyleTimelineOverviewRow[], rows: StyleTimelineOverviewRow[]) {
  return rows.reduce<StyleTimelineOverviewRow[]>((acc, row) => {
    const cachedRow = cache.find((x) => x.rowId === row.rowId);
    if (cachedRow) {
      acc.push(cachedRow);
    } else {
      acc.push(row);
    }
    return acc;
  }, []);
}

export const useStyleTimelineSimulationRows = (selectedStorefrontType?: string) => {
  const { data, collections, loading, error } = useStyleTimelineRows(selectedStorefrontType);
  const [unsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(Module.StyleTimeline);
  const [simulationData, setSimulationData] = useState<StyleTimelineOverviewRow[]>([]);
  const [cache, setCache] = useState<StyleTimelineOverviewRow[]>([]);

  const updateCache = useCallback((rows: StyleTimelineOverviewRow[]) => {
    setCache((prev) => {
      const newCache = [...prev];
      rows.forEach((row) => {
        const index = newCache.findIndex((x) => x.rowId === row.rowId);
        if (index !== -1) {
          newCache[index] = row;
        } else {
          newCache.push(row);
        }
      });
      return newCache;
    });
  }, []);

  useEffect(() => {
    if (unsavedChanges) {
      const newSimulationData = mergeRowsWithCache(cache, data).map((row) => {
        const simulatedRow = unsavedChanges.find((change) => change.rowId === row.rowId);
        if (simulatedRow) {
          return customMerge(row, simulatedRow);
        }
        return row;
      });
      setSimulationData(newSimulationData);
    } else {
      setSimulationData(mergeRowsWithCache(cache, data));
    }
  }, [cache, data, unsavedChanges]);

  return { data: simulationData, collections, loading, error, updateCache };
};
