import { Dexie, type EntityTable } from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback } from 'react';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';

export interface UnsavedChange<TData> {
  key: string;
  changes: TData;
}

export interface UnsavedChangesDatabase extends Dexie {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unsavedChanges: EntityTable<UnsavedChange<any>, 'key'>;
}

export function createUnsavedChangesDatabase() {
  const db = new Dexie('UnsavedChangesDatabase') as UnsavedChangesDatabase;
  db.version(1).stores({
    unsavedChanges: 'key, changes',
  });
  return db;
}

const db = createUnsavedChangesDatabase();

async function setUnsavedChanges<TData>(key: string, changes: TData) {
  await db.unsavedChanges.put({ key, changes });
  dispatchEvent(new Event(UnsavedChangesEvent));
}

// implementation
export function useUnsavedChanges<T>(
  key: string,
  defaultValue?: T
): [typeof defaultValue extends undefined ? T | undefined : T, (arg: T | ((state: T | undefined) => T)) => void] {
  const unsavedChanges = useLiveQuery(() => {
    if (key) {
      return db.unsavedChanges.where({ key }).first();
    }
  }, [key, db]);

  const setValue = useCallback(
    (arg: T | ((state: T) => T)) => {
      let value: T;
      if (arg instanceof Function) {
        value = arg(unsavedChanges?.changes ?? []);
      } else {
        value = arg;
      }
      setUnsavedChanges(key, value);
      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [key, unsavedChanges]
  );

  return [unsavedChanges?.changes ?? defaultValue, setValue];
}
