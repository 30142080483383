import styled from '@emotion/styled';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, SxProps, Theme, Tooltip } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { Module } from 'src/domain';
import { useUnsavedChangesModule } from 'src/hooks';
import { UnsavedChangesBadge } from '../Badge/UnsavedChangesBadge';

export interface DrawerItemProps extends NavLinkProps {
  title: string;
  icon: ReactNode;
  open: boolean;
  sx?: SxProps<Theme>;
  // Exact match determines if we need an exact match for the route or not to highlight it as active, only disable this if you know what you are doing
  exactMatch?: boolean;
}

type StylingProps = {
  open: 1 | 0;
  isactive: 1 | 0;
};

export const DrawerItem: FC<DrawerItemProps> = ({ title, icon, open, sx, exactMatch = true, ...navLinkProps }) => {
  const location = useLocation();
  const unsavedChangeKey = useMemo(
    () => (navLinkProps.to as { pathname: string }).pathname.replace('/', ''),
    [navLinkProps.to]
  );

  const [unsavedChanges] = useUnsavedChangesModule<Record<string, Record<string, unknown[]>>>(
    unsavedChangeKey as Module
  );

  const hasChanges = !!unsavedChanges;

  const isActive = useMemo(() => {
    if (exactMatch) {
      return (navLinkProps.to as { pathname: string }).pathname === location.pathname;
    }
    return location.pathname.toLowerCase().startsWith((navLinkProps.to as { pathname: string }).pathname.toLowerCase());
  }, [exactMatch, navLinkProps.to, location.pathname]);

  return (
    <StyledListItem isactive={isActive ? 1 : 0} open={open ? 1 : 0} disableGutters disablePadding sx={sx}>
      <Tooltip title={!open ? title : ''} placement="right" enterDelay={1500}>
        <NavLink {...navLinkProps} style={{ padding: 0 }}>
          <StyledListItemButton disableGutters isactive={isActive ? 1 : 0} open={open ? 1 : 0}>
            <StyledListItemIcon isactive={isActive ? 1 : 0} open={open ? 1 : 0}>
              <UnsavedChangesBadge hasChanges={hasChanges} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                {icon}
              </UnsavedChangesBadge>
            </StyledListItemIcon>
            <StyledListItemText isactive={isActive ? 1 : 0} open={open ? 1 : 0} primary={title} />
          </StyledListItemButton>
        </NavLink>
      </Tooltip>
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)<StylingProps>`
  transition: ease-in-out 0.5s;
  margin: 0;
  display: block;
  background-color: ${({ isactive, open }) => (isactive && open ? '#B4B4B4' : undefined)};
`;

const StyledListItemButton = styled(ListItemButton)<StylingProps>`
  padding-left: 16px;
  padding-right: 16px;
  min-height: 48px;
  justify-content: ${({ open }) => (open ? 'initial' : 'center')};
  background-color: ${({ isactive }) => (isactive ? '#B4B4B4' : 'none')};
`;

const StyledListItemIcon = styled(ListItemIcon)<StylingProps>`
  color: ${({ isactive }) => (isactive ? '#FFFFFF' : '#333333')};
  min-width: 0;
  margin-right: ${({ open }) => (open ? 3 : 'auto')};
  justify-content: 'center';
`;

const StyledListItemText = styled(ListItemText)<StylingProps>`
  display: ${({ open }) => (!open ? 'none' : 'unset')};
  color: ${({ isactive }) => (isactive ? '#FFFFFF' : '#333333')};
  margin-left: 10px;
`;
