import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Inheritance, InheritanceError, Module, SessionStorageType } from 'src/domain';
import { UnsavedInheritanceChangesEvent } from 'src/domain/events/unsaved-inheritance-changes.event';
import { UnsavedInheritanceKey } from 'src/domain/session-storage/unsaved-inheritance-key';
import { getSplitLevelKey } from 'src/utils/getSplitLevelKey';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useUnsavedChanges } from '../useUnsavedChanges';

type InheritanceSessionStorage = SessionStorageType<Record<Module, { inheritance: Inheritance }>>;
export const useUnsavedInheritance = (module?: Module) => {
  const scope = useScope();
  const id = useCurrentId();
  const currentModule = useCurrentModule();
  const selectedModule = module ?? currentModule;

  const storageKey = UnsavedInheritanceKey;
  const scopeKey = useMemo(() => getSplitLevelKey(scope), [scope]);

  const [storedValue, setStoredValue] = useUnsavedChanges<InheritanceSessionStorage>(storageKey, {});

  const inheritance = useMemo(() => {
    if (!id || !selectedModule) return null;
    const storedInheritance = storedValue[scopeKey]?.[id]?.[selectedModule]?.inheritance;
    return storedInheritance ?? null;
  }, [storedValue, scopeKey, id, selectedModule]);

  const setSessionStorageInheritance = useCallback(
    (inheritance: Inheritance | object) => {
      if (!scope) throw new InheritanceError('Could not find current split level');
      if (!currentModule) throw new InheritanceError('Could not find current module');
      if (typeof id !== 'number') throw new InheritanceError('Could not find current id');

      const updatedValue = {
        ...storedValue,
        [scopeKey]: {
          ...storedValue[scopeKey],
          [id]: {
            ...storedValue[scopeKey]?.[id],
            [currentModule]: {
              inheritance: inheritance,
            },
          },
        },
      } as InheritanceSessionStorage;

      if (isEmpty(inheritance)) delete updatedValue[scopeKey]?.[id]?.[currentModule];
      setStoredValue(updatedValue);
      dispatchEvent(new Event(UnsavedInheritanceChangesEvent));
    },
    [scope, currentModule, id, storedValue, setStoredValue, scopeKey]
  );

  return { inheritance, setSessionStorageInheritance };
};
