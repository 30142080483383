import { BrandImage } from '@bestseller-bit/frontend-community.components.brand-image';
import styled from '@emotion/styled';
import { Box, Skeleton, Stack } from '@mui/material';
import { FC } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { Headings, Module, ScopeEnum, Spacings } from 'src/domain';
import { useCurrentModule, useScope } from 'src/hooks';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { StoreInfoContainer } from '../StoreInfoContainer/StoreInfoContainer';

interface Props {
  store?: StoreModel;
  storeName?: string | null;
  country?: string | null;
  cluster?: string | null;
  partner?: string | null;
  chain?: string | null;
  moduleName?: string | null;
  productLineNumber: string | undefined;
  level?: ScopeEnum;
  loading?: boolean;
}

interface BaseComponentProp {
  title: string | undefined | null;
  productLineNumber: string | undefined;
  hideInfo?: boolean;
  store?: StoreModel;
  partner?: string | null;
}

const BaseComponent: FC<BaseComponentProp> = ({ title, productLineNumber, hideInfo, store, partner }) => {
  return (
    <Stack gap={Spacings.xSmall}>
      {productLineNumber && (
        <Box maxWidth={'250px'}>{productLineNumber && <BrandImage productLineNumber={productLineNumber} />}</Box>
      )}
      <Header heading={Headings.h2}>{title}</Header>
      {!hideInfo && <StoreInfoContainer store={store} partner={partner} />}
    </Stack>
  );
};

const TitleCard: FC<Props> = ({
  level,
  productLineNumber,
  store,
  storeName,
  country,
  cluster,
  moduleName,
  partner,
  loading,
}) => {
  const currentLevel = useScope();
  const levelToUse = level ?? currentLevel;
  const module = useCurrentModule();

  const clusterText = cluster && 'Cluster ' + cluster;

  const ClusterView = <BaseComponent productLineNumber={productLineNumber} title={clusterText} />;
  const PartnerView = <BaseComponent productLineNumber={productLineNumber} title={country} partner={partner} />;
  const StoreView = (
    <BaseComponent
      productLineNumber={productLineNumber}
      title={storeName}
      hideInfo={module === Module.Turnover}
      store={store}
    />
  );
  const ModuleView = <BaseComponent productLineNumber={productLineNumber} title={moduleName} />;

  return (
    <>
      {loading && (
        <TitleCardContainer>
          <Skeleton width={200} height={40} />
          <Skeleton width={260} height={20} />
        </TitleCardContainer>
      )}
      {!loading && (
        <Wrapper>
          <TitleCardContainer>
            {moduleName ? (
              ModuleView
            ) : (
              <>
                {levelToUse === ScopeEnum.CLUSTER && ClusterView}
                {levelToUse === ScopeEnum.PARTNER && PartnerView}
                {levelToUse === ScopeEnum.STORE && StoreView}
              </>
            )}
          </TitleCardContainer>
        </Wrapper>
      )}
    </>
  );
};

const TitleCardContainer = styled(Container)`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const Wrapper = styled.div`
  display: flex;
`;

export default TitleCard;
