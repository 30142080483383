import { useMemo } from 'react';
import { UserSettingModel } from 'src/infrastructure/rest-api/api-types';
import { moduleToModuleEnum } from 'src/utils/modules';
import { useCurrentModule } from './useCurrentModule';
import { useUserSettingsApiQuery } from './useUserSettingsApiQuery';

export const useUserSettings = () => {
  const { data: userSettings, loading, error, refetch } = useUserSettingsApiQuery();
  const currentModule = useCurrentModule();
  const module = moduleToModuleEnum(currentModule);

  const { filterSettings, columnSettings } = useMemo(() => {
    const filterSettings: UserSettingModel[] = [];
    const columnSettings: UserSettingModel[] = [];

    if (userSettings) {
      userSettings.forEach((setting: UserSettingModel) => {
        if (setting.module === module) {
          if (setting.settingsType === 'Filters') {
            filterSettings.push(setting);
          } else if (setting.settingsType === 'Columns') {
            columnSettings.push(setting);
          }
        }
      });
    }

    return { filterSettings, columnSettings };
  }, [userSettings, module]);

  return { filterSettings, columnSettings, loading, error, refetch };
};
