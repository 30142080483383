import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';
import { FC, useMemo } from 'react';
import { usePartners, useType } from 'src/hooks';
import { selectedPartnerVar } from 'src/infrastructure/local_state';

export const PartnerSelection: FC = () => {
  const type = useType();
  const partners = usePartners(type);
  const [selectedPartner, setSelectedPartner] = useReactiveVar(selectedPartnerVar);

  const partnerFound = useMemo(
    () => partners?.find((partner) => partner.compositeId === selectedPartner?.compositeId),
    [partners, selectedPartner?.compositeId]
  );
  const sameCountry = useMemo(() => partners?.every((partner) => partner.country === partners[0]?.country), [partners]);

  return (
    <Wrapper>
      {!!partners?.length && (
        <>
          <Label>Selected Partner:</Label>
          <StyledSelect
            value={partnerFound ? selectedPartner?.compositeId : ''}
            defaultValue={''}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              const newPartner = partners?.find((partner) => partner.compositeId === value);
              if (newPartner) setSelectedPartner(newPartner);
            }}
          >
            <MenuItem disabled value={''}>
              Select a partner
            </MenuItem>
            {partners
              ?.sort((partnerA, partnerB) => partnerA.name?.localeCompare(partnerB.name ?? '') ?? -1)
              ?.map((partner) => (
                <MenuItem key={partner.compositeId} value={partner.compositeId}>
                  {partner.name} {!sameCountry && `(${partner.country})`}
                </MenuItem>
              ))}
          </StyledSelect>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Label = styled.span`
  color: #333;
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
`;

const StyledSelect = styled(Select)`
  height: 35px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: 260px;
  div {
    padding: 0;
  }
`;
