import { ICellRendererParams } from 'ag-grid-community';
import { kebabCase } from 'lodash';
import { useMemo } from 'react';
import { InheritanceModule, Module } from 'src/domain';
import { getInheritanceWarning } from 'src/utils/inheritance-help';

function inheritanceModuleToModule(inheritanceModule: InheritanceModule | undefined): Module | undefined {
  if (!inheritanceModule) return;
  switch (inheritanceModule) {
    case InheritanceModule.SalesBudget:
      return Module.Turnover;
    case InheritanceModule.ProductLineSplit:
      return Module.ProductLineSplit;
    default:
      return kebabCase(inheritanceModule) as Module;
  }
}

export const useInheritanceSettingsCellWarning = (params: ICellRendererParams) => {
  return useMemo(() => {
    const field = params.colDef?.field;
    const module = inheritanceModuleToModule(field as InheritanceModule | undefined);

    if (!module || !field) return '';

    const defaultField = `${field}Default`;

    const defaultInheritance =
      field === 'noosShare' ? params.data[defaultField]?.inheritance : params.data[defaultField];

    if (!defaultInheritance) return '';

    return getInheritanceWarning(module, defaultInheritance, params.value);
  }, [params.colDef?.field, params.value, params.data]);
};
