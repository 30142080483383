import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ClusterInfoRow } from 'src/components/styled/ClusterInfoRow';

export const ClusterStoreHeader: FC = () => {
  return (
    <StyledBox>
      <ClusterInfoRow>
        <div />
        <StyledTypography>Store name</StyledTypography>
        <StyledTypography>Opening</StyledTypography>
        <StyledTypography>Sales iV (next 12 months)</StyledTypography>
        <StyledTypography>Sales iV m2</StyledTypography>
        <StyledTypography>Size</StyledTypography>
      </ClusterInfoRow>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0 0;
  height: 30px;
  margin-top: 24px;
`;

const StyledTypography = styled(Typography)`
  color: rgba(0, 0, 0, 0.5);
`;
