import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';

interface SaveFilterSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (filterName: string) => void;
  isSaving: boolean;
}

const SaveFilterSettingsDialog = ({ open, onClose, onSave, isSaving }: SaveFilterSettingsDialogProps) => {
  const [filterName, setFilterName] = useState('');

  const handleSave = () => {
    onSave(filterName);
    setFilterName('');
  };

  const handleTextFieldKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSave();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <Dialog maxWidth={'xs'} fullWidth open={open} onClose={onClose}>
      <DialogTitle>Create filter settings</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Filter Name"
          fullWidth
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          onKeyDown={handleTextFieldKeyDown}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={'outlined'}>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" autoFocus disabled={!filterName || isSaving}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFilterSettingsDialog;
