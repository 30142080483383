import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { FC, memo, useCallback, useMemo } from 'react';
import { Gender, GenderIdToGenderMap } from 'src/domain';
import { GenderError } from 'src/domain/errors/Gender.error';
import {
  useCurrentId,
  useCurrentModule,
  useGender,
  useGenderProductlinesWithChanges,
  useProductLineId,
  useScope,
} from 'src/hooks';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import { selectedGenderMapVar } from 'src/infrastructure/local_state';
import { SerializeMap } from 'src/utils/SerializeMap';
import { ExclusiveToggleButtonGroup, ToggleButtonInput } from '../ToggleButtonGroup';

interface Props {
  onSelect?: (id: number) => void;
  changes?: Gender[];
}

export const GenderToggleButtonGroup: FC<Props> = memo(({ onSelect, changes }: Props) => {
  const scope = useScope();
  const currentId = useCurrentId();
  const module = useCurrentModule();
  const { data: productLines } = useProductLineGroupsApiQuery();
  const productLineId = useProductLineId();
  const [selectedGenderMap, setSelectedGenderMap] = useReactiveVar(selectedGenderMapVar);
  const defaultGender = useGender();
  const genderProductlinesWithChangesMap = useGenderProductlinesWithChanges();

  const gendersWithChangesForCurrentProductLine = useMemo(
    () => genderProductlinesWithChangesMap?.get(productLines?.find((x) => x.id === productLineId)?.name ?? ''),
    [genderProductlinesWithChangesMap, productLineId, productLines]
  );

  const genderChanges = changes ?? gendersWithChangesForCurrentProductLine;

  const handleSelect = useCallback(
    (id: number) => {
      const gender = GenderIdToGenderMap.get(id);

      if (!gender) throw new GenderError('Could not find selected gender');
      if (!scope) throw new GenderError('Could not find current scope');
      if (!module) throw new GenderError('Could not find current module');
      if (!currentId) throw new GenderError('Could not get current ID ');

      // we need to ensure new reference for reactive var to trigger
      selectedGenderMap.set({ id: currentId, scope, module }, gender);
      setSelectedGenderMap(new SerializeMap(selectedGenderMap));

      onSelect?.(id);
    },
    // we can safely ignore the map since it would cause a loop otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentId, module, onSelect, scope]
  );

  const genders = Array.from(GenderIdToGenderMap)
    // .filter(([_, gender]) => gender !== Gender.Other)
    .reverse()
    // put Gender.Other last
    // .sort((_, b) => (b[1] === Gender.Other ? -1 : 1))
    .map(([id, gender]): ToggleButtonInput => {
      const hasChanges = !!genderChanges?.find((x) => x.toLowerCase() === gender.toLowerCase());

      return {
        id,
        name: gender,
        hasChanges,
        callbackFunction: handleSelect,
      };
    });

  return <ExclusiveToggleButtonGroup header="Genders" buttonInputs={genders} defaultValue={defaultGender} />;
});
