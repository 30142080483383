import { TooltipButtonProps } from '@bestseller-bit/retail-planning.ui.atoms.tooltip-button';
import { Save } from '@mui/icons-material';
import { FC } from 'react';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { Inheritance, Module } from 'src/domain';
import { useCurrentModule, useDefaultInheritance, useInheritance, useUnsavedChangesModule } from 'src/hooks';

interface Props {
  loading?: boolean;
  saveButtonLoading?: boolean;
  disabled?: boolean;
  saveDisabled?: boolean;
  saveButtonTooltip?: string;
  setInheritanceDisabled?: boolean;
  resetDisabled?: boolean;
  onSave?: () => unknown;
  onReset?: () => unknown;
  onSetInheritance?: () => unknown;
}

const SaveButton: FC<Props> = ({
  loading,
  disabled,
  saveButtonLoading,
  saveButtonTooltip,
  saveDisabled,
  setInheritanceDisabled,
  resetDisabled,
  onSave,
  onReset,
  onSetInheritance,
}) => {
  const defaultInheritance = useDefaultInheritance();
  const inheritance = useInheritance();
  const currentModule = useCurrentModule();

  const [unsavedChanges] = useUnsavedChangesModule();
  const hasUnsavedChanges = !!unsavedChanges;

  const isDefaultPartnerOrCluster =
    defaultInheritance === Inheritance.Partner || defaultInheritance === Inheritance.Cluster;

  const isTyped = inheritance === Inheritance.Typed;
  const isHistorical =
    inheritance === Inheritance.Historical ||
    inheritance === Inheritance.NoosDDR ||
    inheritance === Inheritance.NoosHistoricalOrders ||
    inheritance === Inheritance.HistoricalLLY;
  const isPartnerOrCluster = inheritance === Inheritance.Partner || inheritance === Inheritance.Cluster;
  const isInheritanceUnknown =
    (inheritance === undefined || inheritance === null) && currentModule !== Module.InheritanceSettings;
  const isFromPartnerOrClusterToStoreSplitLevel = isDefaultPartnerOrCluster && isTyped;

  const showInheritanceButtonForTurnover =
    currentModule === Module.Turnover &&
    (inheritance !== Inheritance.Typed || defaultInheritance !== inheritance) &&
    !isHistorical &&
    !hasUnsavedChanges;

  const showSaveButtonForTurnover =
    (isTyped || isInheritanceUnknown || hasUnsavedChanges) && currentModule === Module.Turnover;

  const showSaveButtonForInheritanceSettings = currentModule === Module.InheritanceSettings;

  const showSaveButton =
    showSaveButtonForInheritanceSettings ||
    showSaveButtonForTurnover ||
    (currentModule !== Module.Turnover &&
      (isTyped || isInheritanceUnknown) &&
      (!isFromPartnerOrClusterToStoreSplitLevel || hasUnsavedChanges));
  const showResetButton = isHistorical;
  const showSetInheritanceButton =
    isPartnerOrCluster ||
    (isFromPartnerOrClusterToStoreSplitLevel && !hasUnsavedChanges) ||
    showInheritanceButtonForTurnover;

  return (
    <>
      {showSaveButton && !showSetInheritanceButton && (
        <SaveChanges
          onClick={onSave}
          disabled={disabled || saveDisabled || loading}
          loading={saveButtonLoading ?? isInheritanceUnknown}
          tooltip={saveButtonTooltip}
        />
      )}
      {showResetButton && <SaveChanges disabled={disabled || resetDisabled || loading} onClick={onReset} />}
      {showSetInheritanceButton && (
        <SaveChanges onClick={onSetInheritance} disabled={setInheritanceDisabled || disabled || loading} />
      )}
    </>
  );
};

const SaveChanges: FC<TooltipButtonProps> = ({ tooltip, loading, disabled, onClick }) => (
  <StyledLoadingButton
    disabled={disabled}
    tooltip={tooltip}
    size={'small'}
    variant={'contained'}
    startIcon={<Save />}
    onClick={onClick}
    loading={loading}
    loadingPosition="start"
    data-testid="save-button"
  >
    Save changes
  </StyledLoadingButton>
);

export default SaveButton;
