type HttpDownloadMethod = 'GET' | 'POST';

export function handleDownload<TBody>(filename: string, url: string, method: HttpDownloadMethod, body?: TBody) {
  return fetch(url, {
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  }).then((res) => {
    if (res.ok) {
      res.blob().then((blob) => {
        const objectUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = filename ?? 'download.txt';
        a.click();
      });
    } else if (res.status === 401) {
      // Handle unauthorized
      throw new Error('Your session has expired, please reload the page and try again', {
        cause: res.statusText,
      });
    } else if (res.status === 400) {
      res.text().then((message) => {
        throw new Error(message, {
          cause: res.statusText,
        });
      });
    } else {
      // Handle error
      throw new Error('Download failed', {
        cause: res.statusText,
      });
    }
  });
}

type HttpUploadMethod = 'POST' | 'PUT';

export function handleUpload(url: string, method: HttpUploadMethod, body: FormData) {
  return fetch(url, {
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    body,
  }).then((res) => {
    if (res.ok) {
      return;
    } else if (res.status === 401) {
      // Handle unauthorized
      throw new Error('Your session has expired, please reload the page and try again', {
        cause: res.statusText,
      });
    } else if (res.status === 400) {
      res.text().then((message) => {
        throw new Error(message, {
          cause: res.statusText,
        });
      });
    } else {
      throw new Error('Upload failed', {
        cause: res.statusText,
      });
    }
  });
}
