import styled from '@emotion/styled';
import { ArrowBack } from '@mui/icons-material';
import {
  CSSObject,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from 'src/assets/img/logo-storeforecast.svg';
import { Module } from 'src/domain';
import { useDrawerState } from 'src/hooks';
import { useRoutes } from 'src/useRoutes';
import { DrawerSection } from '../DefaultDrawer/DrawerSection';

const openedMixin = (theme: Theme): CSSObject => ({
  height: '100vh',
  width: 300,
  position: 'absolute',
  bottom: 0,
  padding: 0,
  margin: 0,
  backgroundColor: '#ABABAB',
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  height: '0vh',
  width: 300,
  position: 'absolute',
  bottom: 0,
  backgroundColor: '#ABABAB',
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.easeInOut,
    duration: 500,
  }),
  overflowY: 'hidden',
});

const Drawer = muiStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open, theme }) => ({
  width: 300,
  height: '100vh',
  padding: 0,
  margin: 0,
  position: 'absolute',
  bottom: 0,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: '#ABABAB',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const SettingsDrawer: FC = () => {
  const { settingsDrawerOpen, setSettingsDrawerOpen, setDrawerOpen, drawerOpen } = useDrawerState();
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const routes = useRoutes();

  const handleReturn = useCallback(() => {
    setSettingsDrawerOpen(false);
    setShowContent(false);
    if (!drawerOpen) setDrawerOpen((cur) => !cur);
    navigate({ pathname: `/${Module.Turnover}`, search: location.search });
  }, [drawerOpen, location.search, navigate, setDrawerOpen, setSettingsDrawerOpen]);

  const settingsRoutes = routes.filter((x) => x.type === 'setting').filter((x) => x.show !== false);

  useEffect(() => {
    if (!showContent && settingsDrawerOpen) {
      setShowContent(true);
    }
  }, [showContent, settingsDrawerOpen]);

  return (
    <StyledBox>
      <Drawer open={settingsDrawerOpen} variant="permanent">
        <AnimatePresence>
          {showContent && (
            <StyledList
              style={{ display: 'flex', flexDirection: 'column' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.8, ease: 'easeInOut' } }}
              exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeInOut' } }}
            >
              <ListItem sx={{ height: '40px' }}></ListItem>
              <StyledImageItem>{<StyledImage src={logo} />}</StyledImageItem>
              <StyledBackItem>
                <StyledBackButton onClick={handleReturn}>
                  <StyledIcon>
                    <ArrowBack />
                  </StyledIcon>
                  <ListItemText sx={{ marginLeft: '10px' }} primary="Back to Storeforecast" />
                </StyledBackButton>
              </StyledBackItem>
              <DrawerSection dividerText="Settings" routes={settingsRoutes} />
            </StyledList>
          )}
        </AnimatePresence>
      </Drawer>
    </StyledBox>
  );
};

const StyledBox = styled(motion.div)`
  position: absolute;
  bottom: 0;
  min-width: 300px;
  background-color: transparent;
  height: 100vh;
  max-width: 300px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledList = styled(motion.create(List as any))`
  height: 100%;
`;

const StyledBackItem = styled(ListItem)`
  display: 'block';
  transition: 'ease-in-out 0.5s';
  padding: 8px;
`;

const StyledBackButton = styled(ListItemButton)`
  height: 40px;
  min-width: 40px;
  background-color: #fff;
  justify-content: initial;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  :hover {
    background: #fff;
  }
`;

const StyledIcon = styled(ListItemIcon)`
  min-width: 0px;
  margin-right: 3px;
  justify-content: 'center';
`;

const StyledImageItem = styled(ImageListItem)`
  height: 35px;
  min-height: 35px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 150px;
  height: auto;
`;
