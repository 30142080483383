import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { Module, ScopeEnum, SessionStorageType, TurnoverRow } from 'src/domain';
import { ComparableStoreTypeEnum } from 'src/domain/enums/comparableStoreTypeEnum';
import { useScope, useUnsavedStores } from 'src/hooks';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { comparableStoreTypeVar } from 'src/infrastructure/local_state';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { calculatePlannedIndex } from 'src/utils/turnover/calculatePlannedIndex';
import { useTurnoverRows } from '../table/useTurnoverRows';

function diffTurnoverRow(row: TurnoverRow): number {
  if (row.hasBeenChanged && typeof row.previousPlannedSalesIv === 'number') {
    return row.plannedSalesIv - row.previousPlannedSalesIv;
  }
  return 0;
}

function diffAndMergeTurnoverRow(currentDiffValue: number, row: TurnoverRow): number {
  return currentDiffValue + diffTurnoverRow(row);
}

function mergeSumSimulationDataWithUnsavedStores(
  stores: SessionStorageType<TurnoverRow[]> | null | undefined,
  turnoverData: TurnoverRow[],
  allStores: StoreModel[],
  comparableStoreType: ComparableStoreTypeEnum
): TurnoverRow[] {
  if (!stores) {
    return turnoverData;
  }

  const mergedChangesByMonth = Object.entries(stores).reduce((acc, [storeId, changedStores]) => {
    const store = allStores.find((store) => store.id === Number(storeId));
    if (!store) {
      // we should never reach this point - but we need the guard just in case
      return acc;
    }

    if (
      (comparableStoreType === ComparableStoreTypeEnum.Comparables && !store.comparable) ||
      (comparableStoreType === ComparableStoreTypeEnum.NonComparables && store.comparable)
    ) {
      // filter out stores that do not match the value of the comparable store type selector
      return acc;
    }

    const unsavedChanges = changedStores['turnover'] ?? [];
    unsavedChanges.forEach((change) => {
      const currentValue = acc.get(change.month);

      if (typeof currentValue === 'number') {
        acc.set(change.month, diffAndMergeTurnoverRow(currentValue, change));
      } else {
        acc.set(change.month, diffTurnoverRow(change));
      }
    });
    return acc;
  }, new Map<string, number>());

  return turnoverData.map((turnoverData) => {
    const monthDifference = mergedChangesByMonth.get(turnoverData.month);
    if (typeof monthDifference === 'number' && monthDifference !== 0) {
      const newPlannedSalesIv = turnoverData.plannedSalesIv + monthDifference;

      return {
        ...turnoverData,
        plannedSalesIv: newPlannedSalesIv,
        previousPlannedSalesIv: turnoverData.plannedSalesIv,
        plannedIndex: calculatePlannedIndex(1, newPlannedSalesIv, turnoverData.salesIvLy, turnoverData.salesIvLly),
      };
    }
    return turnoverData;
  });
}

export const useTurnoverSimulationRows = () => {
  const scope = useScope();
  const { data: storesData, loading: storesLoading } = useStores();
  const [comparableStoreType] = useReactiveVar(comparableStoreTypeVar);

  const [unsavedChanges] = useUnsavedChangesModule<TurnoverRow[]>(Module.Turnover);
  const { data, loading: turnoverLoading, error } = useTurnoverRows() ?? {};
  const [unsavedStores] = useUnsavedStores<TurnoverRow[]>();

  const loading = turnoverLoading || storesLoading;
  const hasUnsavedChanges = !!unsavedChanges;

  const rows = useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return hasUnsavedChanges ? unsavedChanges : data;
      case ScopeEnum.CLUSTER:
      case ScopeEnum.PARTNER:
        return mergeSumSimulationDataWithUnsavedStores(unsavedStores, data, storesData.allStores, comparableStoreType);
      default:
        return [];
    }
  }, [scope, hasUnsavedChanges, unsavedChanges, data, unsavedStores, storesData.allStores, comparableStoreType]);

  return { data: rows, loading, error };
};
