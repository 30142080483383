import { isNumber } from 'lodash';
import { useMemo } from 'react';
import {
  SalesCampaignDiscountModelOptionalId,
  SalesCampaignOverviewRow,
} from 'src/domain/table/sales-campaign-overview.row';
import {
  calculateDiscountedSalesPrice,
  calculateNewGrossMarginPercent,
} from 'src/utils/gross-margin/calculateGrossMargin';
import { calculatePercentageDifference } from 'src/utils/math';
import { useVat } from '../turnover/useVat';
import { useGrossList } from './useGrossList';
import { useSalesCampaignDiscounts } from './useSalesCampaignDiscounts';
import { useSalesCampaigns } from './useSalesCampaigns';

export const useSalesCampaignRows = (salesCampaignId: number) => {
  const { data: salesCampaigns, loading: salesCampaignLoading, error: salesCampaignsError } = useSalesCampaigns();
  const salesCampaign = salesCampaigns?.find((campaign) => campaign?.id === Number(salesCampaignId));

  // Get gross list
  const {
    data: grossList,
    styleCategories,
    loading: grossListLoading,
    error: grossListError,
  } = useGrossList(salesCampaign?.storefrontType?.name);

  // Get discounts
  // For now only one phase is supported
  const {
    groupedData: groupedDiscounts,
    loading: discountsLoading,
    error: discountsError,
  } = useSalesCampaignDiscounts(salesCampaign?.phases?.[0]?.id);

  const vat = useVat();

  // Merge gross list with discounts
  const data = useMemo(() => {
    if (!grossList || !groupedDiscounts) {
      return null;
    }

    return grossList.map((styleOption) => {
      // For now only one discount can be applied to a style option
      const existingDiscount = groupedDiscounts[styleOption.styleOptionId.toString()]?.[0];

      const discount = existingDiscount
        ? { ...existingDiscount }
        : ({
            id: undefined,
            discountType: 'FIXED_PRICE',
            valueX: 1,
            valueY: null,
            styleOptionId: styleOption.styleOptionId,
            salesCampaignPhaseId: salesCampaign?.phases?.[0]?.id,
            comment: '',
          } as SalesCampaignDiscountModelOptionalId);

      if (discount.discountType === 'X_FOR_FIXED_PRICE' && discount.valueX === 1) {
        discount.discountType = 'FIXED_PRICE';
      }

      const newSalesPrice = isNumber(discount.valueY) ? calculateDiscountedSalesPrice(styleOption, discount) : null;
      return {
        ...styleOption,
        styleCategory: styleCategories?.find((category) => category.id === styleOption.styleCategoryId),
        discount,
        newSalesPrice: newSalesPrice === styleOption.recommendedRetailPriceLcy ? null : newSalesPrice,
        newGMPercent: discount ? calculateNewGrossMarginPercent(styleOption, discount, vat) : null,
        discountPercentage: discount
          ? calculatePercentageDifference(newSalesPrice, styleOption.recommendedRetailPriceLcy)
          : null,
      } as SalesCampaignOverviewRow;
    });
  }, [grossList, groupedDiscounts, salesCampaign?.phases, styleCategories, vat]);

  const eligibleForSale = useMemo(
    () => data?.filter((row) => row.eligibleForSale || isNumber(row.discount.valueY)),
    [data]
  );

  return {
    data: eligibleForSale ?? [],
    allData: data ?? [],
    salesCampaign,
    loading: salesCampaignLoading || grossListLoading || discountsLoading,
    error: salesCampaignsError ?? grossListError ?? discountsError,
  };
};
