import { Filter, GroupLevel } from 'src/domain';
import { ChainMap, StoreGroup, StoreSelectionList } from 'src/domain/models/store/StoreSelection.types';
import { StoreModel, StoreType } from 'src/infrastructure/rest-api/api-types';

interface NameIdPair {
  name: string;
  id: number;
}

export interface GroupedStoresResult {
  groupedStores: StoreSelectionList | undefined;
  allStores: StoreModel[];
  allStoresLookup: Map<number, StoreModel>;
  storeCount: number;
  chains: NameIdPair[];
  clusters: NameIdPair[];
  countries: NameIdPair[];
  partners: NameIdPair[];
  loading?: boolean;
}

export const groupStores = (
  stores: StoreModel[],
  groupBy: GroupLevel | null | undefined,
  storeType: StoreType,
  filter?: Filter
): GroupedStoresResult => {
  let storeCount = 0;
  const chains: NameIdPair[] = stores
    .filter(
      (store) =>
        store.storeType === storeType &&
        doesFilterMatch(
          store,
          groupBy === GroupLevel.Cluster ? (store.cluster?.name ?? '') : (store.country?.name ?? ''),
          {
            clusters: filter?.clusters ?? [],
            countries: filter?.countries ?? [],
            partners: filter?.partners ?? [],
            searchQuery: filter?.searchQuery ?? '',
          } as Filter
        )
    )
    .map((store) => ({ id: store.chain?.id ?? -1, name: store.chain?.name ?? '' }));
  const countries: NameIdPair[] = stores
    .filter(
      (store) =>
        store.storeType === storeType &&
        doesFilterMatch(
          store,
          groupBy === GroupLevel.Cluster ? (store.cluster?.name ?? '') : (store.country?.name ?? ''),
          {
            clusters: filter?.clusters ?? [],
            chains: filter?.chains ?? [],
            partners: filter?.partners ?? [],
            searchQuery: filter?.searchQuery ?? '',
          } as Filter
        )
    )
    .map((store) => ({ id: store.country?.id ?? -1, name: store.country?.name ?? '' }));
  const clusters: NameIdPair[] = stores
    .filter(
      (store) =>
        store.storeType === storeType &&
        doesFilterMatch(
          store,
          groupBy === GroupLevel.Cluster ? (store.cluster?.name ?? '') : (store.country?.name ?? ''),
          {
            chains: filter?.chains ?? [],
            countries: filter?.countries ?? [],
            partners: filter?.partners ?? [],
            searchQuery: filter?.searchQuery ?? '',
          } as Filter
        )
    )
    .map((store) => ({ id: store.cluster?.id ?? -1, name: store.cluster?.name ?? '' }));
  const partners: NameIdPair[] = stores
    .filter(
      (store) =>
        store.storeType === storeType &&
        doesFilterMatch(
          store,
          groupBy === GroupLevel.Cluster ? (store.cluster?.name ?? '') : (store.country?.name ?? ''),
          {
            chains: filter?.chains ?? [],
            countries: filter?.countries ?? [],
            partners: filter?.partners ?? [],
            searchQuery: filter?.searchQuery ?? '',
          } as Filter
        )
    )
    .map((store) => ({ id: store.operationalResponsible?.id ?? -1, name: store.operationalResponsible?.name ?? '' }));

  const groupedStores = stores?.reduce<StoreSelectionList>((acc, store) => {
    if (store.storeType !== storeType || !store.chain?.name) return acc;

    const groupKey = (groupBy === GroupLevel.Cluster ? store.cluster?.name : store.country?.id.toString()) ?? '';
    const groupId = (groupBy === GroupLevel.Cluster ? store.cluster?.id : store.country?.id) ?? -1;
    const groupName = groupBy === GroupLevel.Cluster ? (store.cluster?.name ?? '') : (store.country?.name ?? '');

    if (!doesFilterMatch(store, groupName, filter)) {
      return acc;
    }

    // test partners filter
    if (filter?.partners.length && filter.partners.length > 0) {
      const matchesFilter = filter.partners.some((filter) => filter.value === store.operationalResponsible?.id);
      if (!matchesFilter) {
        return acc;
      }
    }

    storeCount += 1;

    if (!acc.get(store.chain?.id.toString())) {
      acc.set(store.chain?.id.toString(), {
        chainName: store.chain?.name ?? '',
        chainId: store.chain?.id ?? -1,
        groups: new Map(),
      });
    }

    if (!acc.get(store.chain?.id.toString())?.groups.get(groupKey)) {
      acc.get(store.chain?.id.toString())?.groups.set(groupKey, {
        groupKey,
        groupId,
        groupName,
        groupType: groupBy ?? null,
        stores: [],
        chainId: store.chain?.id ?? -1,
        chainName: store.chain?.name ?? '',
      });
    }

    acc.get(store.chain?.id.toString())?.groups.get(groupKey.toString())?.stores.push(store);

    return acc;
  }, new Map());

  return {
    groupedStores: groupedStores ? sortGroupedStores(groupedStores) : groupedStores,
    allStores: stores,
    allStoresLookup: new Map(stores.map((store) => [store.id, store])),
    storeCount,
    chains,
    clusters,
    countries,
    partners,
  };
};

export const sortGroupedStores = (groupedStores: StoreSelectionList): StoreSelectionList => {
  // Sort country/cluster
  const sortedGroups = Array.from(groupedStores.entries()).reduce<[string, ChainMap][]>((acc, [chainId, chain]) => {
    const groupWithSortedStores = Array.from(chain.groups.entries()).reduce<[string, StoreGroup][]>(
      (acc, [groupId, group]) => {
        acc.push([
          groupId,
          { ...group, stores: group.stores.sort((a, b) => (a.storeName ?? '').localeCompare(b.storeName ?? '')) },
        ]);
        return acc;
      },
      []
    );

    acc.push([
      chainId,
      {
        ...chain,
        groups: new Map(groupWithSortedStores.sort((a, b) => a[1].groupName.localeCompare(b[1].groupName))),
      },
    ]);
    return acc;
  }, []);

  // Sort chain
  sortedGroups.sort((a, b) => {
    return a[1].chainName.localeCompare(b[1].chainName);
  });

  return new Map(sortedGroups);
};

const doesFilterMatch = (store: StoreModel, groupName: string, filter?: Partial<Filter>): boolean => {
  let filterMatches = true;

  // test search query
  if (filter?.searchQuery?.storeSelection) {
    const query = filter.searchQuery.storeSelection.toLowerCase();
    filterMatches =
      (filterMatches && store.storeName?.toLowerCase().includes(query)) ||
      store.id.toString().includes(query) ||
      store.chain?.name?.toLowerCase().includes(query) ||
      groupName.toLowerCase().includes(query);
  }

  // test country filter
  if (filter?.countries?.length && filter.countries.length > 0) {
    filterMatches = filterMatches && filter.countries.some((filter) => filter.value === store.country?.id);
  }

  // test chain filter
  if (filter?.chains?.length && filter.chains.length > 0) {
    filterMatches = filterMatches && filter.chains.some((filter) => filter.value === store.chain?.id);
  }

  // test cluster filter
  if (filter?.clusters?.length && filter.clusters.length > 0) {
    filterMatches = filterMatches && filter.clusters.some((filter) => filter.value === store.cluster?.name);
  }

  return filterMatches;
};
