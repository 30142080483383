import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { useChainId } from './chain';
import { useClusterId } from './cluster/useClusterId';
import { usePartnerId } from './partner/usePartnerId';
import { useStoreId } from './store/useStoreId';
import { useScope } from './useScope';

export const useCurrentId = () => {
  const scope = useScope();
  const [selectedPartner] = useReactiveVar(selectedPartnerVar);
  const storeId = useStoreId();
  const clusterId = useClusterId(selectedPartner);
  const partnerId = usePartnerId(selectedPartner);
  const chainId = useChainId();

  return useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return Number(storeId);
      case ScopeEnum.CLUSTER:
        return clusterId;
      case ScopeEnum.PARTNER:
        return partnerId;
      case ScopeEnum.GLOBAL:
        return chainId;
      default:
        return null;
    }
  }, [chainId, clusterId, partnerId, scope, storeId]);
};
