import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { chainIdToNameListVar } from 'src/infrastructure/local_state';
import { useQueryParams } from '../useQueryParams';

export const useChain = () => {
  const [chainIdToNameList] = useReactiveVar(chainIdToNameListVar);
  const { chainId } = useQueryParams();

  const chain = useMemo(() => {
    return chainIdToNameList.get(Number(chainId));
  }, [chainId, chainIdToNameList]);

  return chain;
};
