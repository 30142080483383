import { Delete, Save, SaveAs } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { useState } from 'react';
import SaveFilterSettingsDialog from 'src/components/molecules/SaveFilterSettingsDialog/SaveFilterSettingsDialog';
import { useDialog } from 'src/hooks/useDialog';
import { useSaveUserSettings } from 'src/hooks/useSaveUserSettings';
import { useUserSettings } from 'src/hooks/useUserSettings';
import { UserSettingModel } from 'src/infrastructure/rest-api/api-types';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

interface FiltersDropdownProps {
  gridApi: GridApi | null;
}
const FiltersDropdown = ({ gridApi }: FiltersDropdownProps) => {
  const { filterSettings } = useUserSettings();
  const [selectedFilterId, setSelectedFilterId] = useState<string>('');
  const [filterToDelete, setFilterToDelete] = useState<UserSettingModel | null>(null);
  const { saveUserSettings, updateUserSettings, deleteUserSettings, isSaving } = useSaveUserSettings();
  const { isOpen: isSaveDialogOpen, openDialog: openSaveDialog, closeDialog: closeSaveDialog } = useDialog();
  const { isOpen: isDeleteDialogOpen, openDialog: openDeleteDialog, closeDialog: closeDeleteDialog } = useDialog();

  const handleSelectFilter = (e: SelectChangeEvent) => {
    const selectedFilterId = e.target.value;
    if (!selectedFilterId) return;

    setSelectedFilterId(selectedFilterId);

    const selectedFilter = filterSettings.find((filter) => filter.id === Number(selectedFilterId));
    if (gridApi && selectedFilter) {
      const filterModel = JSON.parse(selectedFilter.settings);
      gridApi.setFilterModel(filterModel);
    }
  };

  const clearFilters = () => {
    setSelectedFilterId('');
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };

  const handleSave = () => {
    if (gridApi) {
      if (selectedFilterId) {
        const selectedFilter = filterSettings.find((filter) => filter.id === Number(selectedFilterId));
        if (!selectedFilter) return;
        const currentFilterModel = gridApi.getFilterModel();
        updateUserSettings(Number(selectedFilterId), selectedFilter?.name, JSON.stringify(currentFilterModel));
      } else {
        openSaveDialog();
      }
    }
  };

  const handleSaveAs = () => {
    if (gridApi) {
      openSaveDialog();
    }
  };

  const handleSaveFilter = (filterName: string) => {
    if (gridApi) {
      const currentFilterModel = gridApi.getFilterModel();
      saveUserSettings(filterName, JSON.stringify(currentFilterModel), 'Filters').then((data) => {
        if (data) {
          setSelectedFilterId(data.id.toString());
        }
      });
    }
    closeSaveDialog();
  };

  const handleDeleteButtonClick = (filter: UserSettingModel, event: React.MouseEvent) => {
    event.stopPropagation();
    setFilterToDelete(filter);
    openDeleteDialog();
  };

  const handleDeleteFilter = () => {
    if (!filterToDelete) return;
    deleteUserSettings(filterToDelete.id);
    clearFilters();
    closeDeleteDialog();
  };

  return (
    <>
      <Box>
        <FormControl size="small">
          <InputLabel>Filters</InputLabel>
          <Select
            size="small"
            sx={{ width: '200px', height: '40px' }}
            value={selectedFilterId}
            onChange={handleSelectFilter}
            label="Filters"
          >
            {filterSettings.map((filter) => (
              <CustomMenuItem key={filter.id} value={filter.id}>
                <ListItemText>{filter.name}</ListItemText>

                <CustomDeleteButton
                  className="delete-icon"
                  edge="end"
                  onClick={(event) => handleDeleteButtonClick(filter, event)}
                >
                  <Delete fontSize="small" />
                </CustomDeleteButton>
              </CustomMenuItem>
            ))}

            <Divider />
            <MenuItem sx={{ paddingY: 0 }} onClick={handleSave} disabled={isSaving}>
              <Button variant="text" startIcon={<Save />}>
                Save
              </Button>
            </MenuItem>
            <MenuItem sx={{ paddingY: 0 }} onClick={handleSaveAs}>
              <Button variant="text" startIcon={<SaveAs />}>
                Save as...
              </Button>
            </MenuItem>
          </Select>
          {selectedFilterId && (
            <FormHelperText sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 4px' }}>
              <Button sx={{ padding: 0, textDecoration: 'underline' }} onClick={clearFilters}>
                Clear filters
              </Button>
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <SaveFilterSettingsDialog
        open={isSaveDialogOpen}
        onClose={closeSaveDialog}
        onSave={handleSaveFilter}
        isSaving={isSaving}
      />
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title="Delete Filter"
        content={`Are you sure you want to delete filter "${filterToDelete?.name}", this action can not be undone.`}
        onCancel={closeDeleteDialog}
        onOk={handleDeleteFilter}
      />
    </>
  );
};
export default FiltersDropdown;

const CustomMenuItem = styled(MenuItem)(() => ({
  maxWidth: '250px',
  minHeight: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover .delete-icon': {
    visibility: 'visible',
    display: 'inline-flex',
  },
  '.delete-icon': {
    visibility: 'hidden',
    display: 'none',
  },
}));

const CustomDeleteButton = styled(IconButton)(() => ({
  visibility: 'hidden',
  display: 'none',
  padding: '2px',
}));
