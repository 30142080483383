import { useCallback, useState } from 'react';
import { UserSettingTypeEnum } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { moduleToModuleEnum } from 'src/utils/modules';
import { useSnackbar } from './snackbar/useSnackbar';
import { useCurrentModule } from './useCurrentModule';

export const useSaveUserSettings = () => {
  const showSnackbar = useSnackbar();
  const currentModule = useCurrentModule();
  const module = moduleToModuleEnum(currentModule);

  const [mutateSaveSettings] = useApiMutation('/api/usersettings', 'post', {
    update(_data, _variables, queryClient) {
      queryClient.invalidateQueries({
        queryKey: ['/api/usersettings'],
      });
    },
  });

  const [mutateUpdateSettings] = useApiMutation('/api/usersettings', 'put', {
    update(_data, _variables, queryClient) {
      queryClient.invalidateQueries({
        queryKey: ['/api/usersettings'],
      });
    },
  });

  const [mutateDeleteSetting] = useApiMutation('/api/usersettings/{id}', 'delete', {
    update(_data, _variables, queryClient) {
      queryClient.invalidateQueries({
        queryKey: ['/api/usersettings'],
      });
    },
  });

  const [isSaving, setIsSaving] = useState(false);

  const saveUserSettings = useCallback(
    async (name: string, settings: string, settingsType: UserSettingTypeEnum) => {
      if (!module) return;
      setIsSaving(true);

      try {
        const payload = {
          name,
          module,
          settingsType,
          settings,
        };

        const response = await mutateSaveSettings({
          body: payload,
        });
        showSnackbar(`${settingsType} settings saved successfully!`, {
          key: 'user-settings',
          variant: 'success',
          preventDuplicate: true,
        });

        return response;
      } catch (error) {
        showSnackbar(`Error saving ${settingsType} settings`, {
          key: 'user-settings-error',
          variant: 'error',
          preventDuplicate: true,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [mutateSaveSettings, showSnackbar, module]
  );

  const updateUserSettings = useCallback(
    async (id: number, name: string, settings: string) => {
      setIsSaving(true);

      try {
        const payload = {
          id,
          name,
          settings,
        };

        await mutateUpdateSettings({ body: payload });
        showSnackbar(`Settings updated successfully!`, {
          key: 'user-settings',
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (error) {
        showSnackbar(`Error updating settings`, {
          key: 'user-settings-error',
          variant: 'error',
          preventDuplicate: true,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [mutateUpdateSettings, showSnackbar]
  );

  const deleteUserSettings = useCallback(
    async (id: number) => {
      setIsSaving(true);

      try {
        const path = { id };
        await mutateDeleteSetting({ path });
        showSnackbar('Setting deleted successfully!', {
          key: 'user-setting-delete',
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (error) {
        showSnackbar('Error deleting setting.', {
          key: 'user-setting-delete-error',
          variant: 'error',
          preventDuplicate: true,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [mutateDeleteSetting, showSnackbar]
  );

  return { saveUserSettings, updateUserSettings, deleteUserSettings, isSaving };
};
