import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useEffect } from 'react';
import { GroupLevel } from 'src/domain';
import { filteredStoresLoadingVar, filteredStoresVar } from 'src/infrastructure/local_state';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { useChainId } from '../chain';
import { useCluster } from '../cluster/useCluster';
import { useCountry } from '../country/useCountry';
import { useCurrentStore } from '../store';
import { useStores } from '../store-selection/queries/useStores';
import { useQueryParams } from '../useQueryParams';
import { useSelectedPartner } from '../useSelectedPartner';
import { useType } from '../useType';

export const useInitializeFilteredStores = () => {
  const [_filteredStores, setFilteredStores] = useReactiveVar(filteredStoresVar);
  const [_filteredStoresLoading, setFilteredStoresLoading] = useReactiveVar(filteredStoresLoadingVar);

  const { clusterId, countryId } = useQueryParams();
  const country = useCountry();
  const cluster = useCluster();
  const chainId = useChainId();
  const [partner] = useSelectedPartner();
  const type = useType();

  const store = useCurrentStore();

  const { data, loading } = useStores(type);
  const { groupedStores } = data;

  useEffect(() => {
    setFilteredStoresLoading(loading);
  }, [loading, setFilteredStoresLoading]);

  useEffect(() => {
    let stores: StoreModel[] = [];
    switch (type) {
      case GroupLevel.Country:
        if (!chainId || !country) break;

        stores = groupedStores?.get(chainId.toString())?.groups.get(countryId ?? '')?.stores ?? [];

        if (cluster || partner) {
          const filteredStores = stores?.reduce<StoreModel[]>((acc, store) => {
            const hasPartner = store.operationalResponsibleComposite?.id === partner?.compositeId;
            if (hasPartner) {
              acc.push(store);
            }
            return acc;
          }, []);
          if (filteredStores?.length) stores = filteredStores;
        }
        setFilteredStores(stores ?? []);

        break;
      case GroupLevel.Cluster:
        if (!chainId || !cluster) return;
        stores = groupedStores?.get(chainId.toString())?.groups.get(cluster)?.stores ?? [];

        if (country || partner) {
          const filteredStores = stores?.reduce<StoreModel[]>((acc, store) => {
            const hasPartner = store.operationalResponsibleComposite?.id === partner?.compositeId;
            if (hasPartner) {
              acc.push(store);
            }
            return acc;
          }, []);

          if (filteredStores?.length) stores = filteredStores;
        }
        setFilteredStores(stores ?? []);
        break;
      default:
        if (store) {
          setFilteredStores([store]);
        } else {
          setFilteredStores([]);
        }
        break;
    }
  }, [chainId, cluster, clusterId, country, countryId, groupedStores, partner, setFilteredStores, store, type]);
};
