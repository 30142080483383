import { SimpleTable, SimpleTableCell, SimpleTableRow } from '@bestseller-bit/retail-planning.ui.atoms.simple-table';
import { Assessment, Delete, Download, Edit, FormatListBulleted } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { differenceInDays, format, parseISO } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DeleteDialog } from 'src/components/organisms/DeleteDialog/DeleteDialog';
import { Headings, Module } from 'src/domain';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useSalesCampaigns } from 'src/hooks/sales-campaigns-planning/useSalesCampaigns';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useFilteredStores } from 'src/hooks/store/useFilteredStores';
import { useStorefrontTypesApiQuery } from 'src/hooks/style-timeline-settings/queries/useStorefrontTypesApiQuery';
import {
  CreateSalesCampaignInput,
  SalesCampaignModel,
  UpdateSalesCampaignInput,
} from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { handleDownload } from 'src/utils/fileUtils';
import { SalesCampaignDialog } from './CreateCampaignDialog';

const formatStringDate = (date: string) => {
  return format(parseISO(date), 'dd/MM-yyyy', { weekStartsOn: 1 });
};

const mapSelectedSalesCampaignToUpdateInput = (
  campaign: SalesCampaignModelWithoutCompositePartnerId
): CreateSalesCampaignInput & UpdateSalesCampaignInput => {
  return {
    endDate: campaign.endDate,
    salesCampaignId: campaign.id,
    startDate: campaign.startDate,
    name: campaign.name ?? '',
    storefrontTypeId: campaign.storefrontType?.id ?? NaN,
    partnerCompositeId: NaN,
  };
};

type SalesCampaignModelWithoutCompositePartnerId = Omit<SalesCampaignModel, 'partnerCompositeId'>;

export const SalesCampaigns: FC = () => {
  const [filteredStores] = useFilteredStores();
  const { data: storefrontTypes, loading: storefrontTypesLoading } = useStorefrontTypesApiQuery();

  const [deleteCampaign] = useApiMutation('/api/salescampaigns/{id}', 'delete', {
    update(data, _variables, queryClient) {
      const path = { partnerId: compositePartner?.id };
      queryClient.setQueryData(['/api/salescampaigns/partners/{partnerId}', path], data);
    },
  });

  const { data: campaigns, loading: salesCampaignsLoading, refetch: refetchCampaigns } = useSalesCampaigns();
  const [isEditing, setIsEditing] = useState(false);
  const [campaignPendingDeletion, setCampaignPendingDeletion] =
    useState<SalesCampaignModelWithoutCompositePartnerId | null>(null);
  const [selectedSalesCampaign, setSelectedSalesCampaign] =
    useState<SalesCampaignModelWithoutCompositePartnerId | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const compositePartner = useCompositePartner();

  const location = useLocation();
  const navigate = useNavigate();

  const loading = storefrontTypesLoading || salesCampaignsLoading;

  const showSnackbar = useSnackbar();

  const [pdfDownloadTarget, setPdfDownloadTarget] = useState<number | null>(null);

  const handleShowAddDialog = useCallback(() => {
    setShowDialog(true);
    setIsEditing(false);
  }, []);

  const handleDialogClose = useCallback(
    (ok: boolean) => {
      if (ok) {
        showSnackbar(`Campaign has been ${isEditing ? 'updated' : 'created'} sucessfully!`, 'success');
        refetchCampaigns();
      }
      setShowDialog(false);
      setIsEditing(false);
    },
    [isEditing, refetchCampaigns, showSnackbar]
  );

  const showDeleteDialog = useCallback((campaign: SalesCampaignModelWithoutCompositePartnerId) => {
    setCampaignPendingDeletion(campaign);
  }, []);

  const handleDelete = useCallback(async () => {
    if (campaignPendingDeletion) {
      await deleteCampaign({ path: { id: campaignPendingDeletion.id } })
        .then(() => {
          showSnackbar(`Campaign "${campaignPendingDeletion.name}" has been deleted!`, 'success');
          refetchCampaigns();
        })
        .catch(() => {
          showSnackbar(`Campaign "${campaignPendingDeletion.name}" was not deleted, please try again.`, 'error');
        })
        .finally(() => {
          setCampaignPendingDeletion(null);
        });
    }
  }, [campaignPendingDeletion, deleteCampaign, refetchCampaigns, showSnackbar]);

  const handleCancelDeletion = useCallback(() => {
    setCampaignPendingDeletion(null);
  }, []);

  const handleEdit = useCallback((campaign: SalesCampaignModelWithoutCompositePartnerId) => {
    setSelectedSalesCampaign(campaign);
    setShowDialog(true);
    setIsEditing(true);
  }, []);

  const [isDownloadingPdfs, setIsDownloadingPdfs] = useState(false);
  const [selectedStores, setSelectedStores] = useState<number[]>([]);
  const toggleStoreSelection = useCallback(
    (storeId: number) => {
      if (selectedStores.includes(storeId)) {
        setSelectedStores(selectedStores.filter((id) => id !== storeId));
      } else {
        setSelectedStores([...selectedStores, storeId]);
      }
    },
    [selectedStores]
  );

  const handleShowDownloadDialog = useCallback((id: number) => {
    setPdfDownloadTarget(id);
    setSelectedStores([]);
  }, []);

  const handlePdfDownload = useCallback(
    (id: number) => {
      setIsDownloadingPdfs(true);
      const generatePdfUrl = `/api/salesCampaigns/${id}/pdf`;
      handleDownload('sales-campaigns-reports.zip', generatePdfUrl, 'POST', selectedStores)
        .then(() => {
          showSnackbar('Your files are being downloaded', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        })
        .catch((err) => {
          showSnackbar(err.message ?? 'Failed to download files', 'error');
        })
        .finally(() => {
          setIsDownloadingPdfs(false);
          setPdfDownloadTarget(null);
        });
    },
    [selectedStores, showSnackbar]
  );

  return (
    <>
      <Container data-testid="salesCampaigns">
        <Stack>
          <Stack direction="row" justifyContent={'space-between'} mb={3}>
            <Header heading={Headings.h1}>Sales & Campaigns</Header>
            <Button data-testid="addCampaign" variant="contained" onClick={handleShowAddDialog}>
              Add campaign
            </Button>
          </Stack>

          {!loading && campaigns && campaigns.length > 0 && (
            <SimpleTable data-testid="salesCampaignsList" columnSizing={['2fr 1.5fr repeat(3, 1fr) 200px']}>
              <SimpleTableRow isHeader>
                <SimpleTableCell>
                  <Typography>Campaign name</Typography>
                </SimpleTableCell>
                <SimpleTableCell>
                  <Typography>Storefront type</Typography>
                </SimpleTableCell>
                <SimpleTableCell>
                  <Typography>Start date</Typography>
                </SimpleTableCell>
                <SimpleTableCell>
                  <Typography>End date</Typography>
                </SimpleTableCell>
                <SimpleTableCell>
                  <Typography>Duration</Typography>
                </SimpleTableCell>
                <SimpleTableCell>
                  <Typography> </Typography>
                </SimpleTableCell>
              </SimpleTableRow>
              {campaigns.map((campaign) => (
                <SimpleTableRow key={campaign.id}>
                  <SimpleTableCell>
                    <Typography>{campaign.name}</Typography>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Typography>{campaign.storefrontType?.name}</Typography>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Typography>{formatStringDate(campaign.startDate)}</Typography>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Typography>{formatStringDate(campaign.endDate)}</Typography>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Typography>
                      {differenceInDays(parseISO(campaign.endDate), parseISO(campaign.startDate))} days
                    </Typography>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Stack direction="row" justifyContent={'flex-end'}>
                      <IconButton
                        data-testid={`showButton-${campaign.id}`}
                        title="Manage campaign items"
                        onClick={() => navigate(`/${Module.SalesCampaignsPlanning}/${campaign.id}${location.search}`)}
                      >
                        <FormatListBulleted color="secondary" />
                      </IconButton>
                      <IconButton
                        data-testid={`reportButton-${campaign.id}`}
                        title="Show sales summary report"
                        onClick={() => navigate(`/${Module.SalesCampaignsReport}/${campaign.id}${location.search}`)}
                      >
                        <Assessment color="secondary" />
                      </IconButton>
                      <IconButton
                        data-testid={`downloadButton-${campaign.id}`}
                        title="Download campaign pdfs"
                        onClick={() => handleShowDownloadDialog(campaign.id)}
                      >
                        <Download color="secondary" />
                      </IconButton>
                      <IconButton
                        data-testid={`editButton-${campaign.id}`}
                        title="Edit campaign details"
                        onClick={() => handleEdit(campaign)}
                      >
                        <Edit color="secondary" />
                      </IconButton>
                      <IconButton
                        data-testid={`deleteButton-${campaign.id}`}
                        title="Delete campaign"
                        onClick={() => showDeleteDialog(campaign)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </Stack>
                  </SimpleTableCell>
                </SimpleTableRow>
              ))}
            </SimpleTable>
          )}
          {!loading && campaigns && campaigns.length < 1 && (
            <Stack alignItems={'center'} gap="0.5rem" mt="1.5rem" data-testid="emptySalesCampaignsList">
              <Typography fontStyle={'italic'}>No campaigns yet. Add one to get started.</Typography>
            </Stack>
          )}
          {campaignPendingDeletion && (
            <DeleteDialog
              open={!!campaignPendingDeletion}
              onCancel={handleCancelDeletion}
              onDelete={handleDelete}
              title="Delete campaign?"
              content="Deleting this campaign is permanent and cannot be undone. All changes related to this campaign will also be deleted. Are you sure you want to delete the campaign?"
            ></DeleteDialog>
          )}
        </Stack>
      </Container>
      {pdfDownloadTarget && (
        <Dialog open={true} onClose={() => setPdfDownloadTarget(null)}>
          <DialogTitle>
            {isDownloadingPdfs
              ? `Please wait while we prepare your file${selectedStores.length > 1 ? 's' : ''}...`
              : 'Select stores to include in PDF report'}
          </DialogTitle>
          <DialogContent>
            {isDownloadingPdfs && (
              <Stack alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Stack>
            )}
            {!isDownloadingPdfs && (
              <Stack>
                <Stack direction="row" justifyContent={'flex-end'}>
                  <Button onClick={() => setSelectedStores(filteredStores.map((store) => store.id))}>Select all</Button>
                  <Button onClick={() => setSelectedStores([])}>Deselect all</Button>
                </Stack>
                <List>
                  {filteredStores.map((store) => (
                    <ListItemButton key={store.id} onClick={() => toggleStoreSelection(store.id)}>
                      <Checkbox checked={selectedStores.includes(store.id)} />
                      <Typography>{store.storeName}</Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Stack>
            )}
          </DialogContent>
          {!isDownloadingPdfs && (
            <DialogActions>
              <Button variant="outlined" onClick={() => setPdfDownloadTarget(null)}>
                Cancel
              </Button>
              <Button
                disabled={selectedStores.length < 1}
                onClick={() => handlePdfDownload(pdfDownloadTarget)}
                variant="contained"
              >
                Download PDFS
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
      {showDialog && (
        <SalesCampaignDialog
          storefrontTypes={storefrontTypes ?? []}
          isEditing={isEditing}
          defaultValue={
            isEditing && selectedSalesCampaign
              ? mapSelectedSalesCampaignToUpdateInput(selectedSalesCampaign)
              : {
                  partnerCompositeId: compositePartner?.id ?? NaN,
                  name: '',
                  startDate: '',
                  endDate: '',
                  storefrontTypeId: NaN,
                  salesCampaignId: NaN,
                }
          }
          open={showDialog}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
};
