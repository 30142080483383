import {
  AccountTree,
  AutoMode,
  Balance,
  CallSplit,
  Campaign,
  Category,
  CrisisAlert,
  DriveFileRenameOutline,
  Event,
  MonetizationOn,
  MoveUp,
  ScatterPlot,
  Schedule,
  SwapVert,
  TableChart,
  Timeline,
  TrendingDown,
  TrendingUp,
  ViewTimeline,
} from '@mui/icons-material';
import { useMemo } from 'react';
import { GenderIcon } from './components/atoms/GenderIcon/GenderIcon';
import { StockManagementIcon } from './components/atoms/StockManagementIcon/StockManagementIcon';
import { AuthenticateGuard } from './components/organisms/AuthenticateGuard/AuthenticateGuard';
import { ProjectedOptionCountGuard } from './components/organisms/ProjectedOptionCountGuard';
import { SelectPartnerGuard } from './components/organisms/SelectPartnerGuard/SelectPartnerGuard';
import { BuyingEventManagement } from './components/views/BuyingEventManagement/BuyingEventManagement';
import { CategorySplit } from './components/views/CategorySplit/CategorySplit';
import { ClusterManagementView } from './components/views/ClusterManagementView/ClusterManagementView';
import { DeliveryProfile } from './components/views/DeliveryProfile/DeliveryProfile';
import { GenderSplit } from './components/views/GenderSplit/GenderSplit';
import { InheritanceSettings } from './components/views/InheritanceSettings/InheritanceSettings';
import { MarkDown } from './components/views/MarkDown/MarkDown';
import { MarkUp } from './components/views/MarkUp/MarkUp';
import NoosShare from './components/views/NoosShare';
import { OpenToBuyLimit } from './components/views/OpenToBuyLimit/OpenToBuyLimit';
import { OptimalOptionCount } from './components/views/OptimalOptionCount/OptimalOptionCount';
import { ProductLineSplit } from './components/views/ProductLineSplit/ProductLineSplit';
import { ProjectedOptionCount } from './components/views/ProjectedOptionCount/ProjectedOptionCount';
import { SalesCampaignReport } from './components/views/SalesCampaignReport/SalesCampaignReport';
import { SalesCampaigns } from './components/views/SalesCampaigns/SalesCampaigns';
import { SalesCampaignsPlanning } from './components/views/SalesCampaignsPlanning/SalesCampaignPlanning';
import { StockManagement } from './components/views/StockManagement/StockManagement';
import { StyleFamilyNameEditor } from './components/views/StyleFamilyNameEditor/StyleFamilyNameEditor';
import { StyleTimeline } from './components/views/StyleTimeline/StyleTimeline';
import { StyleTimelineSettings } from './components/views/StyleTimelineSettings/StyleTimelineSettings';
import { StyleWarnings } from './components/views/StyleWarnings/StyleWarnings';
import { Turnover } from './components/views/Turnover/Turnover';
import { WeekCover } from './components/views/WeekCover/WeekCover';
import { Module, Route, ScopeEnum } from './domain';
import { useIsUsingGender, useIsUsingProductline, useScope } from './hooks';
import { useIsAdmin } from './hooks/auth/useIsAdmin';
import { useIsSuperUser } from './hooks/auth/useIsSuperUser';
import { useIsUsingBuyingEventManagement } from './hooks/chain-settings/useIsUsingBuyingEventManagement';
import { useIsUsingDeliveryProfile } from './hooks/chain-settings/useIsUsingDeliveryProfile';
import { useIsUsingOpenToBuyLimit } from './hooks/chain-settings/useIsUsingOpenToBuyLimit';
import { useFlag } from './hooks/useFlag';

export const useRoutes = () => {
  const productLineActive = useIsUsingProductline();
  const genderSplitActive = useIsUsingGender();

  const showDeliveryProfile = useIsUsingDeliveryProfile();
  const showBuyingEventManagement = useIsUsingBuyingEventManagement();
  const showOpenToBuyLimit = useIsUsingOpenToBuyLimit();

  const showProductLine = productLineActive;
  const showGenderSplit = genderSplitActive && !productLineActive;

  const scope = useScope();
  const isSuperUser = useIsSuperUser();
  const isAdmin = useIsAdmin();

  const salesCampaignsEnabled = useFlag('salesCampaigns');

  const routes = useMemo<readonly Route[]>(
    () => [
      {
        icon: <MonetizationOn />,
        title: 'Sales Budget',
        path: `/${Module.Turnover}`,
        component: <Turnover />,
        type: 'long-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <Category />,
        title: 'Productline Split',
        path: `/${Module.ProductLineSplit}`,
        component: <ProductLineSplit />,
        type: 'long-term',
        show: showProductLine,
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <GenderIcon />,
        title: 'Gender Split',
        path: `/${Module.GenderSplit}`,
        component: <GenderSplit />,
        type: 'long-term',
        show: showGenderSplit,
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <CallSplit />,
        title: 'Category Split',
        path: `/${Module.CategorySplit}`,
        component: <CategorySplit />,
        type: 'long-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <AutoMode />,
        title: 'NOOS Share',
        path: `/${Module.NoosShare}`,
        component: <NoosShare />,
        type: 'long-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <TrendingUp />,
        title: 'Buying Markup',
        path: `/${Module.Markup}`,
        component: <MarkUp />,
        type: 'long-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <Timeline />,
        title: 'Delivery Profile',
        path: `/${Module.DeliveryProfile}`,
        component: <DeliveryProfile />,
        type: 'long-term',
        show: showDeliveryProfile,
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <Balance />,
        title: 'Open To Buy Limit',
        path: `/${Module.OpenToBuyLimit}`,
        component: <OpenToBuyLimit />,
        type: 'long-term',
        show: showOpenToBuyLimit && scope === ScopeEnum.PARTNER,
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <Schedule />,
        title: 'Week Cover',
        path: `/${Module.WeekCover}`,
        component: <WeekCover />,
        type: 'short-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <TrendingDown />,
        title: 'Markdown',
        path: `/${Module.Markdown}`,
        component: <MarkDown />,
        type: 'short-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <StockManagementIcon />,
        title: 'Stock Management',
        path: `/${Module.StockManagement}`,
        component: <StockManagement />,
        type: 'short-term',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <ViewTimeline />,
        title: 'Style Timeline',
        path: `/${Module.StyleTimeline}`,
        component: <StyleTimeline />,
        type: 'range-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard],
        show: (isSuperUser || isAdmin) && scope === ScopeEnum.PARTNER,
      },
      {
        icon: <AccountTree />,
        title: 'Optimal Option Count',
        path: `/${Module.OptimalOptionCount}`,
        component: <OptimalOptionCount />,
        type: 'range-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <TableChart />,
        title: 'Projected Option Count',
        path: `/${Module.ProjectedOptionCount}`,
        component: <ProjectedOptionCount />,
        type: 'range-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard, ProjectedOptionCountGuard],
        show: scope !== ScopeEnum.CLUSTER,
      },
      {
        icon: <CrisisAlert />,
        title: 'Style Warnings',
        path: `/${Module.StyleWarnings}`,
        component: <StyleWarnings />,
        type: 'range-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard],
        show: scope !== ScopeEnum.CLUSTER,
      },
      {
        icon: <Campaign />,
        title: 'Sales & Campaigns',
        path: `/${Module.SalesCampaigns}`,
        component: <SalesCampaigns />,
        type: 'sale-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard],
        show: salesCampaignsEnabled && scope === ScopeEnum.PARTNER,
        exactMatch: false,
      },
      {
        icon: null,
        title: 'Sales & Campaigns planning',
        path: `/${Module.SalesCampaignsPlanning}/:id`,
        component: <SalesCampaignsPlanning />,
        type: 'sale-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard],
        show: false,
      },
      {
        icon: null,
        title: 'Sales campaign report',
        path: `/${Module.SalesCampaignsReport}/:id`,
        component: <SalesCampaignReport />,
        type: 'sale-planning',
        guards: [AuthenticateGuard, SelectPartnerGuard],
        show: false,
      },
      {
        icon: <ScatterPlot />,
        title: 'Cluster Management',
        path: `/settings/${Module.ClusterManagement}`,
        component: <ClusterManagementView />,
        type: 'setting',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <Event />,
        title: 'Buying Event Management',
        path: `/settings/${Module.BuyingEventManagement}`,
        component: <BuyingEventManagement />,
        type: 'setting',
        show: showBuyingEventManagement,
        guards: [AuthenticateGuard],
      },
      {
        icon: <MoveUp />,
        title: 'Inheritance Settings',
        path: `/settings/${Module.InheritanceSettings}`,
        component: <InheritanceSettings />,
        type: 'setting',
        guards: [AuthenticateGuard, SelectPartnerGuard],
      },
      {
        icon: <SwapVert />,
        title: 'Style Timeline Settings',
        path: `/settings/${Module.StyleTimelineSettings}`,
        component: <StyleTimelineSettings />,
        type: 'setting',
        guards: [AuthenticateGuard, SelectPartnerGuard],
        show: isSuperUser || isAdmin,
      },
      {
        icon: <DriveFileRenameOutline />,
        title: 'Style family name editor',
        path: `/settings/${Module.StyleFamilyNameEditor}`,
        component: <StyleFamilyNameEditor />,
        type: 'setting',
        guards: [AuthenticateGuard],
        show: isSuperUser || isAdmin,
      },
    ],
    [
      showProductLine,
      showGenderSplit,
      showDeliveryProfile,
      showOpenToBuyLimit,
      isSuperUser,
      isAdmin,
      scope,
      salesCampaignsEnabled,
      showBuyingEventManagement,
    ]
  );

  return routes;
};
