import { Alert, Button, CircularProgress, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { StorefrontTypeSelect } from 'src/components/molecules/StorefrontTypeSelect/StorefrontTypeSelect';
import { Headings } from 'src/domain';
import { useChainId } from 'src/hooks';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useStorefrontType } from 'src/hooks/style-timeline/useStorefrontType';
import { useStoreType } from 'src/hooks/useStoreType';
import { handleDownload, handleUpload } from 'src/utils/fileUtils';

export const StyleFamilyNameEditor: FC = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const showSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState<string | null>(null);

  const chainId = useChainId();
  const storeType = useStoreType();
  const storefrontType = useStorefrontType();

  const params = useMemo(
    () =>
      new URLSearchParams({
        storeType,
        storefrontType,
      }),
    [storeType, storefrontType]
  );

  const startDownload = useCallback(() => {
    setIsLoading(true);
    setLoadingText('Downloading style family names...');
    handleDownload('stylefamily_names.csv', `/api/stylefamilyname/chains/${chainId}?${params}`, 'GET')
      .then(() => {
        showSnackbar('Download successful', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      })
      .catch((err) => {
        showSnackbar(err.message, {
          variant: 'error',
          preventDuplicate: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setLoadingText(null);
      });
  }, [chainId, params, showSnackbar]);

  const startUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }

      setIsLoading(true);
      setLoadingText('Uploading style family names...');

      const formData = new FormData();
      formData.append('file', file);

      handleUpload(`/api/stylefamilyname/chains/${chainId}`, 'PUT', formData)
        .then(() => {
          showSnackbar('Upload successful', {
            preventDuplicate: true,
            variant: 'success',
            autoHideDuration: 3000,
          });
        })
        .catch((err) => {
          showSnackbar(err.message, {
            variant: 'error',
            preventDuplicate: true,
          });
        })
        .finally(() => {
          setIsLoading(false);
          setLoadingText(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        });
    },
    [chainId, showSnackbar]
  );

  return (
    <>
      <Container>
        <Stack gap={2}>
          <Header heading={Headings.h1}>Style family name editor</Header>
          <Alert severity="info">
            <Typography fontWeight={'bold'}>How to use the editor</Typography>
            <ol>
              <li>
                <Typography>
                  Download the most recent version of the CSV file, by clicking the download button
                </Typography>
              </li>
              <li>
                <Typography>Open the CSV file in a spreadsheet editor (e.g. Excel)</Typography>
              </li>
              <li>
                <Typography>
                  Make the necessary changes in the spreadsheet. Change only the{' '}
                  <Typography component="span" fontWeight={'bold'}>
                    StyleFamilyName
                  </Typography>{' '}
                  column.
                </Typography>
              </li>
              <li>
                <Typography>Save the file</Typography>
              </li>
              <li>
                <Typography>Upload the file by clicking the upload button</Typography>
              </li>
            </ol>
          </Alert>

          <StorefrontTypeSelect />
          <Stack direction="row" gap={2}>
            <Button onClick={startDownload} fullWidth variant="contained">
              Download CSV
            </Button>
            <Button component="label" fullWidth variant="contained">
              Upload CSV
              <input ref={fileInputRef} type="file" hidden onChange={(e) => startUpload(e.target.files?.[0])} />
            </Button>
          </Stack>
        </Stack>
      </Container>

      <Dialog open={isLoading}>
        <DialogContent>
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgress />
            <Typography>{loadingText}</Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
