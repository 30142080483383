import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import styled from '@emotion/styled';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import Container from 'src/components/atoms/Container';
import Loader from 'src/components/atoms/Loader';
import Select from 'src/components/atoms/Select';
import Spacer from 'src/components/atoms/Spacer';
import SearchAndFiltering from 'src/components/molecules/SearchAndFiltering';
import Tabs from 'src/components/molecules/Tabs';
import SelectionContainer from 'src/components/organisms/SelectionContainer/SelectionContainer';
import { useFilterContext } from 'src/contexts/FilterContext';
import { FilterOption, GroupLevel, Spacings, UnsavedChangesStorageKey } from 'src/domain';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { useStoreType } from 'src/hooks/useStoreType';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { StoreType } from 'src/infrastructure/rest-api/api-types';

export const StoreSelection: FC = () => {
  const [filterContext, setFilterContext] = useFilterContext();
  const [_selectedPartner, setSelectedPartner] = useReactiveVar(selectedPartnerVar);
  const storeType = useStoreType();

  // Keep track of previous store type - this is to avoid an inifinite loop when listening for changes on the store type with useEffect
  const previousStoreType = useRef<StoreType | undefined>(storeType);
  useEffect(() => {
    if (previousStoreType.current !== storeType) {
      // When store type changes we need to reset clusters and partners on the filter context otherwise we risk:
      // 1. filtering on locked fields that we cannot clear
      // 2. filtering with data that is not relevant to the current store type
      // in both cases we may end up with no results and in the first case we can never get out of this state again.
      setFilterContext({
        ...filterContext,
        clusters: [],
        partners: [],
      });
    }
    previousStoreType.current = storeType;
  }, [filterContext, storeType, setFilterContext]);

  const { data, loading, error } = useStores(filterContext.grouping, storeType, filterContext);
  const { groupedStores, storeCount, chains, clusters, partners, countries } = data;

  const chainFilterOptions: FilterOption[] = useMemo(() => {
    return chains.map((chain) => ({
      label: chain.name,
      value: chain.id,
    }));
  }, [chains]);

  const countryFilterOptions: FilterOption[] = useMemo(() => {
    return countries.map((country) => ({
      label: country.name,
      value: country.id,
    }));
  }, [countries]);

  const clusterFilterOptions: FilterOption[] = useMemo(() => {
    return clusters.map((cluster) => ({
      label: cluster.name,
      value: cluster.name,
    }));
  }, [clusters]);

  const partnerFilterOptions: FilterOption[] = useMemo(() => {
    return partners.map((partner) => ({
      label: partner.name,
      value: partner.id,
    }));
  }, [partners]);

  const noResults = !loading && storeCount <= 0;
  const showStores = !loading && storeCount > 0;

  const handleActiveGroup = useCallback(
    (activeGroup: string) => {
      setFilterContext({
        ...filterContext,
        grouping: activeGroup as GroupLevel,
      });
    },
    [filterContext, setFilterContext]
  );

  const clearUserData = useCallback(() => {
    // Remove all unsaved changes when the user visits store selection page
    if (sessionStorage.getItem(UnsavedChangesStorageKey)) {
      sessionStorage.removeItem(UnsavedChangesStorageKey);
    }

    setSelectedPartner(undefined);
  }, [setSelectedPartner]);

  useEffect(() => {
    clearUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledStoreSelectionView>
      <SearchAndFiltering
        groupLevel={filterContext.grouping}
        chains={chainFilterOptions}
        countries={countryFilterOptions}
        clusters={clusterFilterOptions}
        partners={partnerFilterOptions}
        width="100%"
      />
      <List width="100%">
        {loading && <Loader />}

        {!loading && (
          <>
            <ListHeaderWrapper>
              <Tabs
                tabOneTitle={'PARTNER STORES'}
                tabTwoTitle={'OWN STORES'}
                tabThreeTitle={'SEMI OWNED STORES'}
                tabFourTitle={'WHOLESALE STORES'}
                tabFiveTitle={'OWN ECOM'}
                style={{ width: '100%' }}
              />
              <Select
                label="Group by"
                size="medium"
                activeItem={filterContext.grouping}
                options={[GroupLevel.Country, GroupLevel.Cluster]}
                onSelect={handleActiveGroup}
              />
            </ListHeaderWrapper>
            <Spacer spacing={Spacings.xSmall} />

            {noResults && <NoResults>{error?.message ?? '0 results found'}</NoResults>}
            {showStores && groupedStores && <SelectionContainer stores={groupedStores} storeType={storeType} />}
          </>
        )}
      </List>
    </StyledStoreSelectionView>
  );
};

const NoResults = styled.p`
  text-align: center;
`;

const StyledStoreSelectionView = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  row-gap: 10px;
`;

const List = styled(Container)<{ width: string }>`
  display: flex;
  flex-flow: column;
  row-gap: 15px;
  min-width: 640px;
  width: ${(props) => props.width};
  min-height: 50px;
  padding: 20px 15px;
  box-sizing: border-box;
`;

const ListHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
