import { useMemo } from 'react';
import { Module } from 'src/domain';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { mapStyleTimelineDataToOverviewRows } from 'src/mapping/style-timeline.mapping';
import { useStores } from '../store-selection/queries/useStores';
import { useStyleCategoriesQuery } from '../style-categories/queries/useStyleCategoriesQuery';
import { usePartnerStyleTimelineQuery } from '../style-timeline/queries/usePartnerStyleTimelineQuery';
import { useStyleTimelineCollectionsQuery } from '../style-timeline/queries/useStyleTimelineCollectionsQuery';
import { useUnsavedChangesModule } from '../unsaved-changes';

export const useGrossList = (storefrontType: string | undefined | null) => {
  const { data: partnerStyleTimelines, loading, error } = usePartnerStyleTimelineQuery(storefrontType);

  const {
    data: collections,
    loading: collectionsLoading,
    error: collectionsError,
  } = useStyleTimelineCollectionsQuery(storefrontType);
  const {
    data: styleCategories,
    loading: styleCategoriesLoading,
    error: styleCategoriesError,
  } = useStyleCategoriesQuery();

  const [unsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(Module.StyleTimeline);

  const { data: stores } = useStores();

  const styleTimelineData = useMemo(
    () =>
      mapStyleTimelineDataToOverviewRows(
        partnerStyleTimelines,
        collections,
        styleCategories,
        stores.allStores
      ) as StyleTimelineOverviewRow[],
    [collections, partnerStyleTimelines, stores.allStores, styleCategories]
  );

  const mergedStyleTimelineData = useMemo(() => {
    return styleTimelineData.map((row) => {
      const unsavedChange = unsavedChanges?.find((change) => change.id === row.id);
      return {
        ...row,
        ...unsavedChange,
      };
    });
  }, [styleTimelineData, unsavedChanges]);

  return {
    data: mergedStyleTimelineData,
    styleCategories: styleCategories,
    loading: loading || collectionsLoading || styleCategoriesLoading,
    error: error ?? collectionsError ?? styleCategoriesError,
  };
};
