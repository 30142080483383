import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { selectedPartnerVar } from 'src/infrastructure/local_state';

import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useInitializeIsMarkdownHistorical } from 'src/hooks/historical';
import { components } from 'src/infrastructure/rest-api/generated';
import { useMarkdownApiQuery } from './useMarkdownApiQuery';

export type Markdown = components['schemas']['MarkdownModel'];

export type MarkdownData = {
  storeMarkdown?: Markdown[];
  clusterMarkdown?: Markdown[];
  partnerMarkdown?: Markdown[];
};

export type MarkdownLevelQuery = {
  data: MarkdownData;
  error: Error | null;
  loading: boolean;
};

export const useMarkdownLevelQuery = (): MarkdownLevelQuery | undefined => {
  const storeId = useStoreId();
  const [selectedPartner] = useReactiveVar(selectedPartnerVar);
  const clusterId = useClusterId(selectedPartner);
  const compositePartner = useCompositePartner();

  const storeResponse = useMarkdownApiQuery(storeId, 'Store');
  const clusterResponse = useMarkdownApiQuery(clusterId, 'Cluster');
  const partnerResponse = useMarkdownApiQuery(compositePartner?.id, 'Partner');

  const response = useMemo<MarkdownLevelQuery | undefined>(() => {
    return {
      data: {
        storeMarkdown: storeResponse.data,
        clusterMarkdown: clusterResponse.data,
        partnerMarkdown: partnerResponse.data,
      },
      error: storeResponse.error ?? clusterResponse.error ?? partnerResponse.error,
      loading: storeResponse.loading || clusterResponse.loading || partnerResponse.loading,
    };
  }, [
    storeResponse.data,
    storeResponse.error,
    storeResponse.loading,
    clusterResponse.data,
    clusterResponse.error,
    clusterResponse.loading,
    partnerResponse.data,
    partnerResponse.error,
    partnerResponse.loading,
  ]);

  useInitializeIsMarkdownHistorical(response?.data);

  return response;
};
