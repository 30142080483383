import { useContext, useEffect, useRef, useState } from 'react';
import { StoreCacheContext } from 'src/contexts/StoreCacheContext';
import { Filter, GroupLevel } from 'src/domain';
import { useStoreType } from 'src/hooks/useStoreType';
import { useType } from 'src/hooks/useType';
import { StoreType } from 'src/infrastructure/rest-api/api-types';
import { GroupedStoresResult } from 'src/mapping/store.mapping';

export const useStores = (groupBy?: GroupLevel | null, storeType?: StoreType, filter?: Filter) => {
  const [getStores, { error }] = useContext(StoreCacheContext);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<GroupedStoresResult>({
    groupedStores: undefined,
    storeCount: 0,
    chains: [],
    clusters: [],
    countries: [],
    partners: [],
    allStores: [],
    allStoresLookup: new Map(),
  });

  const defaultGroupBy = useType();
  const selectedGroupBy = groupBy ?? defaultGroupBy;
  const defaultStoreType = useStoreType();
  const selectedStoreType = storeType ?? defaultStoreType;

  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!selectedStoreType) {
      return;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const result = getStores(selectedGroupBy, selectedStoreType, filter);
      setData(result);
      setLoading(result.loading ?? false);
    }, 500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [selectedGroupBy, selectedStoreType, filter, getStores]);

  return {
    data,
    loading,
    error,
  };
};
