import { Box, CircularProgress, Link, Stack, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { useAccount, useMsal } from '@azure/msal-react';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { StickyHelpButton } from './components/molecules/StickyHelpButton/StickyHelpButton';
import { StickyNewsLetterButton } from './components/molecules/StickyNewsletterButton/StickyNewsletterButton';
import { Drawer } from './components/organisms/Drawer/Drawer';
import { useHasDevTools } from './hooks/useHasDevTools';
import { useIsStatusPage } from './hooks/useIsStatusPage';
import { useLaunchDarkly } from './hooks/useLaunchDarkly';

import { Router } from './Router';
import Container from './components/atoms/Container';
import { StatusWidget } from './components/molecules/StatusWidget/StatusWidget';
import { Appbar } from './components/organisms/Appbar/Appbar';
import { DevTools } from './components/organisms/DevTools/DevTools';
import { useStores } from './hooks/store-selection/queries/useStores';
import useScrollRestoration from './hooks/useScrollRestoration';

export const AppContent = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const location = useLocation();
  const showDrawer = useMemo(
    () => !!account && !['/', '/no-access', '/status'].includes(location.pathname),
    [account, location.pathname]
  );

  const showAppBar = showDrawer;

  useLaunchDarkly(accounts[0]);

  const isStatusPage = useIsStatusPage();
  const hasDevTools = useHasDevTools();

  const scrollableRef = useRef<HTMLElement>(null);
  useScrollRestoration(scrollableRef);

  const { data: storesData, loading: storesLoading } = useStores();

  if (storesLoading || !storesData) {
    return (
      <AppContainer hasSidebar={false}>
        <StyledContainer hasSidebar={false}>
          <StyledInnerContainer height="100%" hasDevTools={false} isStatusPage={false}>
            <Stack height="100%" alignItems={'center'} justifyContent={'center'}>
              <CircularProgress sx={{ color: 'black' }} />
            </Stack>
          </StyledInnerContainer>
        </StyledContainer>
      </AppContainer>
    );
  }

  return (
    <AppContainer hasSidebar={showDrawer}>
      <ErrorBoundary
        fallback={
          <StyledContainer hasSidebar={false}>
            <StyledInnerContainer hasDevTools={false} isStatusPage={false}>
              <Container>
                <Stack gap="1rem">
                  <Typography noWrap={false}>Sorry, something went wrong. We have logged the issue.</Typography>
                  <Typography>
                    If you need help, you can reach out to us via the request help link in the lower right corner or via
                    email to{' '}
                    <Link href="mailto:techretailplanning@bestseller.com">techretailplanning@bestseller.com</Link>
                  </Typography>
                  <Typography>
                    You can try to{' '}
                    <Link href="#" onClick={() => window.location.reload()}>
                      reload the page
                    </Link>{' '}
                    it might be a temporary problem. If the problem persists, please contact us.
                  </Typography>
                </Stack>
              </Container>
            </StyledInnerContainer>
          </StyledContainer>
        }
      >
        {showDrawer && <Drawer data-testid="drawer" />}
        <StyledContainer hasSidebar={showDrawer} ref={scrollableRef}>
          {showAppBar && <Appbar />}
          <StyledInnerContainer hasDevTools={hasDevTools} isStatusPage={isStatusPage}>
            <Router />
          </StyledInnerContainer>
        </StyledContainer>

        {!isStatusPage && <StatusWidget />}
        {hasDevTools && <DevTools />}
      </ErrorBoundary>
      {!showDrawer && !isStatusPage && <StickyNewsLetterButton />}
      {!isStatusPage && <StickyHelpButton />}
    </AppContainer>
  );
};

const AppContainer = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'hasSidebar';
  },
})<{ hasSidebar: boolean }>`
  display: flex;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`;

const StyledContainer = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'hasSidebar';
  },
})<{ hasSidebar: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => `${props.hasSidebar ? 'flex-start' : 'center'}`};
  overflow: auto;
  position: relative;
  box-sizing: border-box;
`;

const StyledInnerContainer = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'isStatusPage' && propName !== 'hasDevTools';
  },
})<{ isStatusPage: boolean; hasDevTools: boolean }>`
  padding-right: ${(props) => `${props.hasDevTools && !props.isStatusPage ? '50px !important' : '0'}`};
  width: 100%;
  padding: ${(props) => `${props.isStatusPage ? '0' : '20px'}`};
  box-sizing: border-box;
`;
