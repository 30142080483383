import styled from '@emotion/styled';
import { List, ListItem, Skeleton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import Spacer from 'src/components/atoms/Spacer';
import Searchbar from 'src/components/molecules/Searchbar';
import { SidebarListItem } from 'src/components/molecules/SidebarItem/SidebarListItem';
import { GroupLevel, SessionStorageType, Spacings, UnsavedChangesStoresKey } from 'src/domain';
import { useFilteredStores, useQueryParams, useType, useUnsavedChanges } from 'src/hooks';
import { useFilteredStoresParentName } from 'src/hooks/useFilteredStoresParentName';
import { buildPathFromUrl } from 'src/utils/buildPath';
import { useBuild } from 'src/utils/buildPathFromStore';

const ListItemSkeleton = () => {
  return (
    <StyledListItem>
      <StyledSkeleton variant="rectangular" />
    </StyledListItem>
  );
};

export const DefaultSidebar: FC = () => {
  const url = useLocation();
  const type = useType();
  const { storeType } = useQueryParams();
  const [stores, { loading: filteredStoresLoading }] = useFilteredStores();
  const [unsavedChanges] = useUnsavedChanges<SessionStorageType>(UnsavedChangesStoresKey);
  const { countryId, cluster, partner, storeId, chainId } = useQueryParams();

  const [search, setSearch] = useState('');

  const sidebarTitle = useFilteredStoresParentName();

  const indentIndex = useMemo(() => {
    switch (type) {
      case GroupLevel.Cluster:
      case GroupLevel.Country:
        return 1;
      default:
        return undefined;
    }
  }, [type]);

  const filteredStores = useMemo(() => {
    return stores
      .filter((store) => store.storeName?.includesInsensitive(search))
      .sort((a, b) => {
        return a.storeName && b.storeName ? a.storeName.localeCompare(b.storeName) : 0;
      });
  }, [stores, search]);

  const buildStoreUrl = useBuild();

  const overviewUrl = useMemo(
    () => ({
      pathname: url.pathname,
      search: buildPathFromUrl(
        chainId ?? '',
        storeType ?? '',
        type ?? '',
        'overview',
        countryId ?? '',
        cluster ?? '',
        partner ?? ''
      ),
    }),
    [chainId, cluster, countryId, partner, storeType, type, url.pathname]
  );

  const SidebarParentItem = useCallback(
    (text: string | null, indent?: 1 | 2) => (
      <SidebarListItem text={text} indent={indent} isActive={storeId === 'overview'} to={overviewUrl} />
    ),
    [overviewUrl, storeId]
  );

  const onResetInputField = useCallback(() => {
    setSearch('');
  }, []);

  const onUpdateUserInput = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(event.currentTarget.value);
  }, []);

  return (
    <StyledList>
      <Spacer spacing={Spacings.xSmall} vertical />
      <ListItem sx={{ padding: '12px' }}>
        <Searchbar
          width="100%"
          style={{ padding: 0 }}
          placeholder="Search"
          resetInputField={onResetInputField}
          value={search}
          updateUserInput={onUpdateUserInput}
        />
      </ListItem>
      {SidebarParentItem(sidebarTitle)}
      {filteredStoresLoading && (
        <>
          <ListItemSkeleton />
          <ListItemSkeleton />
          <ListItemSkeleton />
        </>
      )}
      {!filteredStoresLoading && filteredStores.isEmpty() && (
        <>
          <Typography mt={1} variant="body2">
            No stores matches search
          </Typography>
        </>
      )}
      {filteredStores.map((store) => (
        <SidebarListItem
          indent={indentIndex}
          key={store.id}
          text={store.storeName}
          isActive={storeId === store.id.toString()}
          hasChanges={!isEmpty(unsavedChanges?.[store.id]?.[url.pathname.slice(1)])}
          to={buildStoreUrl(store)}
        />
      ))}
      <Spacer spacing={Spacings.xLarge} vertical />
    </StyledList>
  );
};

const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: '100vh';
  padding: 0px 4px;
`;

const StyledListItem = styled(ListItem)`
  height: 30px;
  width: 208px;
  margin: 1px 0px;
  margin-left: 8px;
  padding: 0px;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 30px;
  border-radius: 8px;
  padding: 0px;
  margin: 1px 0px;
  width: 208px;
`;
