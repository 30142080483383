import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { FC, useCallback, useEffect } from 'react';
import Select from 'src/components/atoms/Select';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { ComparableStoreTypeEnum } from 'src/domain/enums/comparableStoreTypeEnum';
import { comparableStoreTypeVar } from 'src/infrastructure/local_state';

const comparableStoreTypes: GroupedOption[] = [
  {
    isHeader: false,
    display: 'All stores',
    value: ComparableStoreTypeEnum.AllStores.toString(),
  },
  {
    isHeader: false,
    display: 'Comparable stores',
    value: ComparableStoreTypeEnum.Comparables.toString(),
  },
  {
    isHeader: false,
    display: 'Non-comparable stores',
    value: ComparableStoreTypeEnum.NonComparables.toString(),
  },
] as GroupedOption[];

export const ComparableStoreSelect: FC = () => {
  const [comparableStoreType, setComparableStoreType] = useReactiveVar(comparableStoreTypeVar);

  useEffect(() => {
    if (comparableStoreTypes.length > 0) {
      const hasComparableStoreType = comparableStoreTypes.some(
        (x) => x.value === ComparableStoreTypeEnum.AllStores.toString()
      );
      if (!hasComparableStoreType) {
        setComparableStoreType(Number(comparableStoreTypes[0].value));
      }
    }
  }, [comparableStoreType, setComparableStoreType]);

  const handleComparableStoreTypeChange = useCallback(
    (value: string) => {
      setComparableStoreType(Number(value));
    },
    [setComparableStoreType]
  );

  return (
    <Select
      width={170}
      size="small"
      options={comparableStoreTypes}
      onSelect={handleComparableStoreTypeChange}
      activeItem={comparableStoreType.toString()}
      label="Select comparable filter"
    ></Select>
  );
};
