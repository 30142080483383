import { equals } from 'ramda';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Module } from 'src/domain';
import { SalesCampaignDiscountModelOptionalId } from 'src/domain/table/sales-campaign-overview.row';
import {
  CreateSalesCampaignDiscountInput,
  UpdateSalesCampaignDiscountInput,
} from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useDiscardChangesModule } from '../discard-changes/useDiscardChangesModule';
import { useCompositePartner } from '../partner/useCompositePartner';
import defaultErrorMessage from '../snackbar/defaultErrorMessage';
import { useSnackbar } from '../snackbar/useSnackbar';
import { useSalesCampaignRows } from './useSalesCampaignRows';
import { useSalesCampaignSimulationRows } from './useSalesCampaignSimulationRows';

function mapToInput(
  discount: SalesCampaignDiscountModelOptionalId
): CreateSalesCampaignDiscountInput | UpdateSalesCampaignDiscountInput {
  return {
    discountType: discount.discountType === 'FIXED_PRICE' ? 'X_FOR_FIXED_PRICE' : discount.discountType,
    salesCampaignDiscountId: discount.id,
    salesCampaignPhaseId: discount.salesCampaignPhaseId,
    styleOptionId: discount.styleOptionId,
    valueX: discount.valueX,
    valueY: discount.valueY,
    comment: discount.comment ?? '',
  };
}

export const useSalesCampaignPlanningSaveChanges = () => {
  const { id: salesCampaignId } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const { data } = useSalesCampaignRows(Number(salesCampaignId));
  const { data: simulationData } = useSalesCampaignSimulationRows(Number(salesCampaignId));
  const discardChanges = useDiscardChangesModule(Module.SalesCampaignsPlanning);
  const compositePartner = useCompositePartner();
  const showSnackbar = useSnackbar();

  const [createDiscount] = useApiMutation('/api/salescampaigns/discounts', 'post', {
    update(data, _variables, queryClient) {
      queryClient.invalidateQueries({
        queryKey: [`/api/salescampaigns/partners/${compositePartner?.id}`, undefined],
      });
      queryClient.invalidateQueries({
        queryKey: [`/api/salescampaigns/phase/${data[0].salesCampaignPhaseId}/discounts`, undefined],
      });
    },
  });

  const [updateDiscount] = useApiMutation('/api/salescampaigns/discounts', 'put', {
    update(data, _variables, queryClient) {
      queryClient.invalidateQueries({
        queryKey: [`/api/salescampaigns/partners/${compositePartner?.id}`, undefined],
      });
      queryClient.invalidateQueries({
        queryKey: [`/api/salescampaigns/phase/${data[0].salesCampaignPhaseId}/discounts`, undefined],
      });
    },
  });

  const [deleteDiscount] = useApiMutation('/api/salescampaigns/discounts', 'delete', {
    update(_data, _variables, queryClient) {
      queryClient.invalidateQueries({
        queryKey: [`/api/salescampaigns/partners/${compositePartner?.id}`, undefined],
      });

      queryClient.invalidateQueries({
        queryKey: [`/api/salescampaigns/phase/${simulationData[0].discount.salesCampaignPhaseId}/discounts`, undefined],
      });
    },
  });

  const update = useCallback(() => {
    if (!simulationData) {
      // No simulation data exists
      return;
    }

    const changedRows = simulationData?.filter((row) => {
      const originalRow = data?.find((r) => r.id === row.id);
      return !equals(originalRow?.discount, row.discount);
    });

    if (changedRows.length <= 0) {
      // No changes to save
      return;
    }

    const discounts = changedRows.map((data) => data.discount);
    const toUpdateOrCreate = discounts.filter((d) => {
      // Filter out rows that do not have all required values
      return d && d.valueX && d.valueY;
    });

    // Create list for updates and list for creates
    const toUpdate = toUpdateOrCreate?.filter((d) => d.id).map(mapToInput) as UpdateSalesCampaignDiscountInput[];
    const toCreate = toUpdateOrCreate?.filter((d) => !d.id).map(mapToInput) as CreateSalesCampaignDiscountInput[];
    const toDelete = discounts
      .filter((d) => {
        return d && d.id && (!d.valueX || !d.valueY);
      })
      .map(
        (d) =>
          ({
            salesCampaignDiscountId: d.id,
          }) as { salesCampaignDiscountId: number }
      );

    setIsSaving(true);

    const promises: Promise<unknown>[] = [];

    if (toUpdate.length > 0) {
      promises.push(updateDiscount({ body: toUpdate }));
    }

    if (toCreate.length > 0) {
      promises.push(createDiscount({ body: toCreate }));
    }

    if (toDelete.length > 0) {
      promises.push(deleteDiscount({ body: toDelete }));
    }

    return Promise.all(promises)
      .then(() => {
        discardChanges();
      })
      .catch(() => {
        showSnackbar(defaultErrorMessage, 'error');
      })
      .finally(() => setIsSaving(false));
  }, [simulationData, data, updateDiscount, createDiscount, deleteDiscount, discardChanges, showSnackbar]);

  return [update, { isSaving }] as const;
};
