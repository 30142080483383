import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import styled from '@emotion/styled';
import { AppBar, Toolbar } from '@mui/material';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Module } from 'src/domain';
import { useCurrentModule } from 'src/hooks';
import { useIsAdmin } from 'src/hooks/auth/useIsAdmin';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { PartnerSelection } from '../PartnerSelection/PartnerSelection';

export const Appbar: FC = () => {
  const location = useLocation();
  const isStatusPage = useMemo(() => location.pathname === '/status', [location.pathname]);
  const isAdmin = useIsAdmin();
  const hasDevTools = useMemo(() => isAdmin && !isStatusPage, [isAdmin, isStatusPage]);
  const [selectedPartner, _] = useReactiveVar(selectedPartnerVar);
  const currentModule = useCurrentModule();

  const showPartnerSelection = useMemo(
    () => selectedPartner && currentModule !== Module.StyleFamilyNameEditor,
    [currentModule, selectedPartner]
  );

  return (
    <StyledAppBar data-testid="appbar" position="sticky" hasToolbar={hasDevTools && !isStatusPage}>
      <StyledToolbar variant="dense">{showPartnerSelection && <PartnerSelection />}</StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp(propName) {
    return !['hasToolbar'].includes(propName);
  },
})<{ hasToolbar: boolean }>`
  height: 55px;
  padding-right: ${(props) => `${props.hasToolbar ? '-30px' : '0'}`};
  background-color: #c4c4c4;
  z-index: 2;
`;
const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;
